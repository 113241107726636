import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './DateSelector.scss';

const DateSelector = ({
  label,
  value,
  minDate,
  handleChange = () => {},
  disableDate,
}) => {
  const [dateValue, setDateValue] = useState(value);
  const [miniumDate, setMinimumDate] = useState(new Date().getDate() - 1);

  useEffect(() => {
    if (minDate) {
      setMinimumDate(minDate);
      setDateValue(value);
      // let epochValue = value?.unix() * 1000; // milliseconds
      // handleChange(epochValue);
    }
  }, [JSON.stringify(minDate)]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        value={dateValue}
        onChange={(newValue) => {
          let epochValue = newValue?.unix() * 1000; // milliseconds
          setDateValue(newValue);
          handleChange(epochValue);
        }}
        minDate={miniumDate}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" margin="normal" fullWidth />
        )}
        mobileButtons={[
          {
            type: 'button',
            className: 'rmdp-button rmdp-action-button',
          },
        ]}
        className="customized-datepicker"
        disablePast={false}
        disabled={disableDate}
      />
    </LocalizationProvider>
  );
};

export default DateSelector;
