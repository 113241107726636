import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { ErrorMessage } from 'formik';

import './InputSlider.scss';

const InputSlider = ({
  unitType,
  classes,
  label,
  fieldName,
  currentValue,
  disabled,
  changeHandler,
  minValue = 1,
  maxValue = 100,
  isErrorMessage = true,
  suffixString = false,
  suffixText, // Text to add as suffix in Input field
  disabledInputField = false, // To disable or enable only input field not slider
  step = 1, // Increment or decrement of slider + and - click
  decimalPlaces, // Input value upto decimal places
  isDetection = false,
  ...props
}) => {
  const [currentSliderValue, setCurrentSliderValue] = useState(
    currentValue || minValue,
  );

  const handleChange = (value) => {
    let result = value;
    if (decimalPlaces) {
      result = (Math.round(value * 100) / 100).toFixed(decimalPlaces);
    }
    setCurrentSliderValue(+result);
    changeHandler(+result);
  };

  useEffect(() => {
    isDetection && setCurrentSliderValue(currentValue || minValue);
  }, [currentValue]);

  const handleIncrement = () => {
    if (currentSliderValue < maxValue) {
      handleChange(currentSliderValue + step);
    }
  };

  const handleDecrement = () => {
    if (currentSliderValue > minValue) {
      handleChange(currentSliderValue - step);
    }
  };

  const formatValue = (value) => {
    if (value < 1024) {
      return `${decimalPlaces ? value.toFixed(decimalPlaces) : value}`;
    } else {
      return `${(value / 1024).toFixed(1)}`;
    }
  };

  const getSuffix = (value) => {
    if (value < 1024) {
      return `Kbps`;
    } else {
      return `Mbps`;
    }
  };

  return (
    <div className={`range-field-wrap ${classes ? classes : ''}`}>
      {label && <label className="range-field-label-slider">{label}</label>}
      <div className="range-field-container-input-slider">
        <button
          type="button"
          onClick={handleDecrement}
          disabled={disabled || currentSliderValue === minValue}
        >
          -
        </button>
        <ReactSlider
          name={fieldName}
          step={step}
          value={currentSliderValue}
          min={minValue}
          max={maxValue}
          disabled={disabled}
          className="range-slider-new"
          trackClassName="range-slider-track"
          thumbClassName="range-slider-thumb"
          onChange={handleChange}
          onAfterChange={changeHandler}
        />
        <button
          type="button"
          onClick={handleIncrement}
          disabled={disabled || currentSliderValue === maxValue}
        >
          +
        </button>
        <div
          className={`input-text-parent ${!unitType ? 'parent-wiothoutlabel' : ''}`}
        >
          <input
            className={`slider-input ${unitType ? 'suffix-input' : 'without-suffix'}`}
            type="text"
            value={
              unitType || decimalPlaces
                ? formatValue(currentSliderValue)
                : suffixString
                  ? `${currentSliderValue} ${suffixString}`
                  : currentSliderValue
            }
            disabled={disabledInputField || disabled}
          />
          {(suffixText || unitType) && (
            <span className="suffix-slider">
              {suffixText ? suffixText : getSuffix(currentSliderValue)}
            </span>
          )}
        </div>
        {isErrorMessage && (
          <ErrorMessage component="div" name={fieldName} className="error" />
        )}
      </div>
    </div>
  );
};

export default InputSlider;
