import { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { ErrorMessage } from 'formik';

import './InputSlider.scss';

export const InputSlider = ({
  unitType,
  classes,
  label,
  fieldName,
  currentValue,
  disabled,
  changeHandler,
  minValue,
  maxValue,
  minValueLabel,
  maxValueLabel,
  focusSlider,
  isErrorMessage = true,
  suffixString = false,
  ...props
}) => {
  const [currentSliderValue, setCurrentSliderValue] = useState(
    currentValue || (!focusSlider ? minValue : ''),
  );
  const [action, setAction] = useState(null);

  const handleChange = (value) => {
    setCurrentSliderValue(value);
    changeHandler(value);
  };

  const handleIncrement = () => {
    if (focusSlider) {
      handleChange('+1');
    } else if (currentSliderValue < maxValue) {
      handleChange(currentSliderValue + 1);
    }
  };

  const handleDecrement = () => {
    if (focusSlider) {
      handleChange('-1');
    } else if (currentSliderValue > minValue) {
      handleChange(currentSliderValue - 1);
    }
  };

  const handleNeutral = () => {
    handleChange('0');
  };

  useEffect(() => {
    let interval;
    if (action) {
      interval = setInterval(() => {
        if (action === 'decrement') {
          handleDecrement();
        } else if (action === 'increment') {
          handleIncrement();
        } else if (action === 'neutral') {
          handleNeutral();
        }
      }, 100);
    }
    return () => clearInterval(interval);
  }, [action, handleDecrement, handleIncrement]);

  const formatValue = (value) => {
    if (value < 1024) {
      return `${value}`;
    } else {
      return `${(value / 1024).toFixed(1)}`;
    }
  };

  const getSuffix = (value) => {
    if (value < 1024) {
      return `Kbps`;
    } else {
      return `Mbps`;
    }
  };

  return (
    <div className={`range-field-wrap ${classes ? classes : ''}`}>
      {label && <label className="range-field-label-slider">{label}</label>}
      <div className="range-field-container-input-slider">
        <button
          type="button"
          onClick={handleDecrement}
          disabled={disabled || currentSliderValue === minValue}
          onMouseDown={() => setAction('decrement')}
          onMouseUp={() =>
            focusSlider ? setAction('neutral') : setAction(null)
          }
          onMouseLeave={() => setAction(null)}
        >
          -
        </button>
        <ReactSlider
          name={fieldName}
          value={currentSliderValue}
          min={minValue}
          max={maxValue}
          disabled={disabled}
          className="range-slider-new"
          trackClassName="range-slider-track"
          thumbClassName={!focusSlider && 'range-slider-thumb'}
          onAfterChange={changeHandler}
          renderTrack={(props, state) => (
            <div {...props}>
              {state?.index === 0 && (
                <span className="range-slider-start-value">
                  {minValueLabel && !unitType
                    ? minValueLabel
                    : minValue && !unitType
                      ? minValue
                      : formatValue(minValue)}
                </span>
              )}
              {state?.index === 1 && (
                <span className="range-slider-last-value">
                  {maxValueLabel && !unitType
                    ? maxValueLabel
                    : maxValue && !unitType
                      ? maxValue
                      : formatValue(maxValue)}
                </span>
              )}
            </div>
          )}
        />
        <button
          type="button"
          onClick={handleIncrement}
          disabled={disabled || currentSliderValue === maxValue}
          onMouseDown={() => setAction('increment')}
          onMouseUp={() =>
            focusSlider ? setAction('neutral') : setAction(null)
          }
          onMouseLeave={() => setAction(null)}
        >
          +
        </button>
        <div
          className={`input-text-parent ${
            !unitType ? 'parent-wiothoutlabel' : ''
          }`}
        >
          <input
            className={`slider-input ${
              unitType ? 'suffix-input' : 'without-suffix'
            }`}
            type="text"
            value={
              unitType
                ? formatValue(currentSliderValue)
                : suffixString
                  ? `${currentSliderValue} ${suffixString}`
                  : currentSliderValue
            }
            disabled={disabled}
          />
          {unitType && (
            <span className="suffix-slider">
              {getSuffix(currentSliderValue)}
            </span>
          )}
        </div>
        {isErrorMessage && (
          <ErrorMessage component="div" name={fieldName} className="error" />
        )}
      </div>
    </div>
  );
};
