import { Utils } from '../../../../helpers';
import { mqttPublish } from '../../../../utils/connection/mqttConnection';

export default function publishDeviceSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `ch/${deviceInfo.deviceId}/${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function deleteDeviceSetting(resolutionObj, resource, deviceInfo) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'delete',
        resource: `ch/${deviceInfo.deviceId}/${resource}`,
        ...{ properties: { ...resolutionObj } },
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishHubDeviceSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `ch/${deviceInfo.hubId}/${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishFirmwareDeviceSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishFirmwareDeviceChannalSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `ch/${deviceInfo.deviceId}/${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishDeviceLocalStorageSetting(resource, deviceInfo) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `${resource}`,
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishGetDeviceLocalStorageSetting(resource, deviceInfo) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'get',
        resource: `${resource}`,
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishNetworkChannelLocalSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'set',
        resource: `${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishAddDeviceSetting(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'add',
        resource: `${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  //TODO DELETE LATER
  Utils.vmsLogger().log('keep alive devices', JSON.parse(context?.payload));
  mqttPublish(context);
}

export function deleteKeepLiveSession(resolutionObj, resource, deviceInfo) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'delete',
        resource: `${resource}`,
        ...{ properties: { ...resolutionObj } },
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}

export function publishDeviceAccessUiStatus(
  resolutionObj,
  resource,
  deviceInfo,
  isRestart = false,
) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: deviceInfo.devicePublishId,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: `${deviceInfo.hubId}`,
      from: `${deviceInfo.accountId}`,
      msg: {
        action: 'get',
        resource: `${resource}`,
        ...(!isRestart && { properties: { ...resolutionObj } }),
      },
      publish: `d/notify/${deviceInfo.accountId}/${deviceInfo.uuid}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}
