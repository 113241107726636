import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Utils, constants, customEventName } from '../../../../helpers';
import '../stepsnotification.scss';
import { Form, Card, Col } from 'react-bootstrap';
import RegionSelect from 'react-region-select';
import { IoIosSearch } from 'react-icons/io';
// import CameraWallSnapshot from '../../../devices/listDevice/blocks/CameraWallSnapshot';
import { getCDNInfo } from '../../../../store/reducers/StreamingReducer';
import { getDeviceInformation } from '../../../../store/reducers/AccountReducer';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import './StepCustomNotification.scss';
import LineCrossingSnapShot from '../../../devices/listDevice/blocks/LineCrossingSnapShot';
import InclusionExclusionSnapShot from '../../../devices/listDevice/blocks/InclusionExclusionSnapShot';
import StepIndicator from '../StepIndicator';
import { PrimaryButton } from '../../../../components/common';

const StepCustomNotification = (props) => {
  const [imageWidth, setImageWidth] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(1.7);
  const [searchQuery, setSearchQuery] = useState('');
  const cdnInfo = useSelector(getCDNInfo);
  const deviceDetails = useSelector(getDeviceInformation);
  const snapShotContainerRef = useRef(null); // Ref to access the snapshot container
  const colors = Utils.getAllColors();
  const scollToRef = useRef();
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);

  useEffect(() => {
    setTimeout(() => {
      scollToRef?.current?.scrollIntoView();
    }, 100);
    getStepsData()?.triggers.length &&
      props?.setVirtualLineUuid(getStepsData()?.triggers[0]?.properties?.ids);
  }, []);

  useEffect(() => {
    if (props?.customLocationId?.toString()?.trim().length) {
      props?.handleSelectCustomLocation(props?.customLocationId);
      props?.setCustomLocationId(props?.customLocationId);
      getStepsData()?.triggers.length &&
        props?.setVirtualLineUuid(getStepsData()?.triggers[0]?.properties?.ids);
    }
  }, [props?.customLocationId]);

  const handleSelect = (e, deviceId, uuid) => {
    const { checked } = e.target;
    if (checked) {
      props?.setDeviceIds((prevDeviceIds) => {
        const newDeviceIds = new Set(prevDeviceIds);
        newDeviceIds.add(deviceId);
        return Array.from(newDeviceIds);
      });
      props?.setVirtualLineUuid((prevIds) => {
        const newIds = new Set(prevIds);
        newIds.add(uuid);
        return Array.from(newIds);
      });
    } else {
      props?.setDeviceIds((prevDeviceIds) => {
        const newDeviceIds = new Set(prevDeviceIds);
        newDeviceIds.delete(deviceId);
        return Array.from(newDeviceIds);
      });
      props?.setVirtualLineUuid((prevIds) => {
        const newIds = new Set(prevIds);
        newIds.delete(uuid);
        return Array.from(newIds);
      });
    }
  };

  const getNormalCoordinates = (SPx, SPy, EPx, EPy, item) => {
    const referenceResolution =
      item?.properties?.['reference-resolution']?.split('x');
    const REGION_X_RANGE = +referenceResolution?.[0] || 1;
    const REGION_Y_RANGE = +referenceResolution?.[1] || 1;
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const scaleX = divResolutionWidth / parseInt(REGION_X_RANGE - 1);
    const scaleY = divResolutionHeight / parseInt(REGION_Y_RANGE - 1);

    const normalStartPoint = {
      x: SPx * scaleX,
      y: SPy * scaleY,
    };

    const normalEndPoint = {
      x: EPx * scaleX,
      y: EPy * scaleY,
    };

    return { startPoint: normalStartPoint, endPoint: normalEndPoint };
  };

  const getZoneDimensions = (BLx, BLy, BRx, BRy, TRx, TRy, TLx, TLy, item) => {
    const referenceResolution =
      item?.properties?.['reference-resolution']?.split('x');
    const REGION_X_RANGE = +referenceResolution?.[0] || 1;
    const REGION_Y_RANGE = +referenceResolution?.[1] || 1;
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;
    const left = +TLx / proportionWidth / (divResolutionWidth / 100);
    const top = +TLy / proportionHeight / (divResolutionHeight / 100);
    const width = (+BRx - +BLx) / proportionWidth / (divResolutionWidth / 100);
    const height =
      (+TLy - +BLy) / proportionHeight / (divResolutionHeight / 100);

    return {
      left: Math.abs(left),
      top: Math.abs(top),
      width: Math.abs(width),
      height: Math.abs(height),
      TLx,
      TLy,
      TRx,
      TRy,
      BLx,
      BLy,
      BRx,
      BRy,
    };
  };

  const getNormalCoordinatesArea = (
    BLx,
    BLy,
    BRx,
    BRy,
    TRx,
    TRy,
    TLx,
    TLy,
    item,
  ) => {
    const dimensions = getZoneDimensions(
      BLx,
      BLy,
      BRx,
      BRy,
      TRx,
      TRy,
      TLx,
      TLy,
      item,
    );
    const color = colors[item['virtual-area-index']];
    return {
      x: dimensions.left,
      y: dimensions.top,
      width: dimensions.width,
      height: dimensions.height,
      index: item['virtual-area-index'],
      data: {
        zoneName: item['virtual-area-name'],
        regionColor: color,
        regionStyle: {
          border: `2px solid ${color}`,
        },
        x: dimensions.left,
        y: dimensions.top,
        width: dimensions.width,
        height: dimensions.height,
        ...dimensions,
      },
      isChanging: false,
    };
  };

  const filteredVirtualLinesData = () => {
    const allLines = props?.virtualLinesData.map((item) => {
      const dimensions = getNormalCoordinates(
        ...item['virtual-line-coordinates']?.split(','),
        item,
      );
      return { ...item, ...dimensions };
    });
    return searchQuery
      ? allLines?.filter((item) => {
          const lineName = item['virtual-line-name']?.toLowerCase() || '';
          const areaName = item.areaName?.toLowerCase() || '';
          const cameraName = item.cameraName?.toLowerCase() || '';
          const query = searchQuery.toLowerCase();
          return (
            lineName.includes(query) ||
            areaName.includes(query) ||
            cameraName.includes(query)
          );
        })
      : allLines;
  };

  const filteredVirtualAreasData = () => {
    const allLines = props?.virtualAreasData?.map((item) => {
      let dimensions;
      if (item['virtual-area-coordinates']) {
        dimensions = getNormalCoordinatesArea(
          ...item['virtual-area-coordinates']?.split(','),
          item,
        );
      }
      return { ...item, ...dimensions };
    });
    return searchQuery
      ? allLines?.filter((item) => {
          const lineName = item['virtual-area-name']?.toLowerCase() || '';
          const areaName = item.areaName?.toLowerCase() || '';
          const cameraName = item.cameraName?.toLowerCase() || '';
          const query = searchQuery.toLowerCase();
          return (
            lineName.includes(query) ||
            areaName.includes(query) ||
            cameraName.includes(query)
          );
        })
      : allLines;
  };

  const moveToNextStep = () => {
    const nextStepData = {};
    if (props?.isEdit) {
      nextStepData['triggers'] = getStepsData()?.triggers;
      nextStepData['selectedEvents'] = getStepsData()?.selectedEvents;
      nextStepData['editItem'] = getStepsData()?.editItem;
      nextStepData['devices'] = getStepsData()?.devices;
      nextStepData['conditions'] = getStepsData()?.conditions;
      nextStepData['actions'] = getStepsData()?.actions;
      nextStepData['subscribers'] = getStepsData()?.subscribers;
    } else {
      nextStepData['triggers'] = props?.stepData?.triggers;
      nextStepData['selectedEvents'] = props?.stepData?.selectedEvents;
      nextStepData['devices'] = props?.deviceIds;
    }
    if (props?.virtualLineUuid.length) {
      const updatedTriggerList = getStepsData()?.triggers.map((item) => ({
        ...item,
        properties: {
          ...item.properties,
          ids: props?.virtualLineUuid,
        },
      }));
      nextStepData['triggers'] = updatedTriggerList;
    }
    setStepData(nextStepData);
    props.nextStep(nextStepData);
  };
  return (
    <div className="create-notification">
      <StepIndicator {...props} />
      {(
        props?.isShowArea
          ? props?.virtualAreasData?.length
          : props?.virtualLinesData?.length
      ) ? (
        <>
          <div className="device-selection-row mt-3">
            <div className="step-title-desc-section">
              {props?.isShowArea
                ? constants.SELECT_LOCATION_TITLE_VIRTUAL_AREA
                : constants.SELECT_LOCATION_TITLE_LINE}
            </div>
          </div>
          <div className="device-selection-row mb-3 mt-1">
            <div className="step-desc-section">
              {props?.isShowArea
                ? constants.LOCATION_DESCRIPTION_VIRTUAL_AREA
                : constants.LOCATION_DESCRIPTION_LINE_CROSS}
            </div>
          </div>
          <div className="search-input-container mt-2">
            <IoIosSearch className="search-icon" />
            <Form.Control
              className="search-input"
              type="text"
              placeholder={
                props?.isShowArea
                  ? constants.NOTIFICATION_SEARCH_HEADER_VIRTUAL_AREA
                  : constants.NOTIFICATION_SEARCH_HEADER
              }
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {props?.isShowArea ? (
            <div className="card-container-notification">
              {filteredVirtualAreasData()?.map((item, key) => (
                <Col xs={12} sm={6} className="mb-4" key={key}>
                  <Card className="card-notification-custom">
                    <div
                      className="snap-shot-container"
                      ref={snapShotContainerRef}
                    >
                      <div className="live-snapshot">
                        <InclusionExclusionSnapShot
                          key={item.deviceId}
                          deviceElemId={`camera${item.deviceId}`}
                          orgId={item?.orgId}
                          cdnInfo={cdnInfo}
                          locationAreaName={item.locationAreaNames}
                          isHighlighted={false}
                          showZoomOptions={false}
                          conStatus={item.connectionStatus || 'offline'}
                          hubId={item.gatewayId}
                          displayDeviceStatus={Utils.getDeviceStatus(
                            item?.deviceStatus,
                            item?.connectionStatus,
                          )}
                          setImageWidth={setImageWidth}
                          aspectRatio={aspectRatio}
                          {...item}
                        />
                        <RegionSelect
                          id="region-select"
                          maxRegions={1}
                          regions={[item]}
                          regionStyle={{
                            outline: 'none',
                            zIndex: 70,
                          }}
                          className={`region-container region${item?.index}`}
                          style={{
                            border: '0px solid black',
                            position: 'absolute',
                            height: '-webkit-fill-available',
                            display: 'inline-block',
                            width: imageWidth,
                          }}
                          constraint={true}
                        ></RegionSelect>
                      </div>
                    </div>
                    <Card.Body className="card-notification-body">
                      <Card.Title className="card-notification-custom-heading">
                        <span>{item['virtual-area-name']}</span>
                        <span>
                          <input
                            name={item['virtual-area-uuid']}
                            className="input-notification"
                            type="checkbox"
                            checked={props?.virtualLineUuid?.includes(
                              item['virtual-area-uuid'],
                            )}
                            onChange={(e) => {
                              handleSelect(
                                e,
                                item.deviceId,
                                item['virtual-area-uuid'],
                              );
                            }}
                          />
                        </span>
                      </Card.Title>
                      <Card.Text className="card-notification-custom-heading-2">
                        {item.areaName} - {item.cameraName}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </div>
          ) : (
            <div className="card-container-notification">
              {filteredVirtualLinesData()?.map((item, key) => (
                // item.connectionStatus !== 'offline' &&
                <Col xs={12} sm={6} className="mb-4" key={key}>
                  <Card className="card-notification-custom">
                    <div
                      className="snap-shot-container"
                      ref={snapShotContainerRef}
                    >
                      <div className="live-snapshot">
                        <LineCrossingSnapShot
                          key={item.deviceId}
                          customNotification={true}
                          deviceElemId={`camera${item.deviceId}`}
                          orgId={item?.orgId}
                          cdnInfo={cdnInfo}
                          locationAreaName={item.locationAreaNames}
                          isHighlighted={false}
                          showZoomOptions={false}
                          conStatus={item.connectionStatus || 'offline'}
                          hubId={item.gatewayId}
                          displayDeviceStatus={Utils.getDeviceStatus(
                            item?.deviceStatus,
                            item?.connectionStatus,
                          )}
                          setImageWidth={setImageWidth}
                          aspectRatio={aspectRatio}
                          {...item}
                        />
                        <svg
                          width={
                            document.getElementById(`camera${item.deviceId}`)
                              ?.offsetWidth
                          }
                          height={
                            document.getElementById(`camera${item.deviceId}`)
                              ?.offsetHeight
                          }
                          style={{ position: 'absolute', top: 0, zIndex: 10 }}
                        >
                          <line
                            key={item.deviceId + key}
                            x1={item.startPoint.x}
                            y1={item.startPoint.y}
                            x2={item.endPoint.x}
                            y2={item.endPoint.y}
                            stroke={'#4B93FB'}
                            strokeWidth="2"
                          />
                          <line
                            x1={(item.startPoint.x + item.endPoint.x) / 2}
                            y1={(item.startPoint.y + item.endPoint.y) / 2 - 10}
                            x2={(item.startPoint.x + item.endPoint.x) / 2}
                            y2={(item.startPoint.y + item.endPoint.y) / 2 + 10}
                            stroke="#4B93FB"
                            strokeWidth="2"
                            strokeDasharray="4,4"
                          />
                          <circle
                            cx={item.endPoint.x}
                            cy={item.endPoint.y}
                            r="4"
                            fill="#4B93FB"
                          />
                          <circle
                            cx={item.startPoint.x}
                            cy={item.startPoint.y}
                            r="4"
                            fill="#4B93FB"
                          />
                          {(item['virtual-line-direction'] === 'left' ||
                            item['virtual-line-direction'] === 'both') && (
                            <polygon
                              points={`
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                                2 -
                                              5
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 -
                                              10
                                            }
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                                2 +
                                              5
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 -
                                              10
                                            }
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                              2
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 -
                                              15
                                            }
                                        `}
                              fill="#4B93FB"
                            />
                          )}
                          {(item['virtual-line-direction'] === 'right' ||
                            item['virtual-line-direction'] === 'both') && (
                            <polygon
                              points={`
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                                2 -
                                              5
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 +
                                              10
                                            }
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                                2 +
                                              5
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 +
                                              10
                                            }
                                            ${
                                              (item.startPoint.x +
                                                item.endPoint.x) /
                                              2
                                            },${
                                              (item.startPoint.y +
                                                item.endPoint.y) /
                                                2 +
                                              15
                                            }
                                        `}
                              fill="#4B93FB"
                            />
                          )}
                        </svg>
                      </div>
                    </div>
                    <Card.Body className="card-notification-body">
                      <Card.Title className="card-notification-custom-heading">
                        <span>{item['virtual-line-name']}</span>
                        <span>
                          <input
                            name={item['virtual-line-uuid']}
                            className="input-notification"
                            type="checkbox"
                            checked={props?.virtualLineUuid?.includes(
                              item['virtual-line-uuid'],
                            )}
                            onChange={(e) =>
                              handleSelect(
                                e,
                                item.deviceId,
                                item['virtual-line-uuid'],
                              )
                            }
                          />
                        </span>
                      </Card.Title>
                      <Card.Text className="card-notification-custom-heading-2">
                        {item.areaName} - {item.cameraName}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </div>
          )}
        </>
      ) : null}
      <PrimaryButton
        className="adddevice-btn"
        fontSize="14px"
        backgroundColor={getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--primary_40')}
        height="44px"
        color="#FFFFFF"
        type="submit"
        disabled={!props?.virtualLineUuid?.length}
        onClick={() => moveToNextStep()}
      >
        {constants.DEVICES_CONTINUE_TEXT}
      </PrimaryButton>
    </div>
  );
};

export default StepCustomNotification;
