import { useState, useEffect } from 'react';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { PrimaryButton } from '../../../../components/common';
import StepIndicator from '../StepIndicator';
import { Utils, constants } from '../../../../helpers';
import {
  appearDisappear,
  CameraNotification,
  Enter,
  Exit,
  Fog,
  Intrusion,
  LineCrossing,
  LoiteringCustomEvent,
  MotionDetection,
  Person,
  Shock,
  Tampering,
  VehicleNotification,
  DefocusIcon,
} from '../../../../assets/images';

import '../stepsnotification.scss';

const Two = (props) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [triggerList, setTriggerList] = useState([
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_CAMERA,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_CAMERA_DESCRIPTION,
      isSelected: false,
      icon: CameraNotification,
      type: 'ai.duclo.CameraStatus',
      eventClass: [],
      internalEvent: ['online', 'offline', 'license-expire'],
      resource: Utils.getNotificationResources().camera,
      resourceFog: Utils.getNotificationResources().fog,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_PERSON,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_PERSON_DESCRIPTION,
      isSelected: false,
      icon: Person,
      type: 'ai.duclo.PersonDetected',
      eventClass: [Utils.getNotificationEventName().person],
      resource: Utils.getNotificationResources().person,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_VEHICLE,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_VEHICLE_DESCRIPTION,
      isSelected: false,
      icon: VehicleNotification,
      type: 'ai.duclo.VehicleDetected',
      eventClass: [Utils.getNotificationEventName().vehicle],
      resource: Utils.getNotificationResources().vehicle,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_SHOCK,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_SHOCK_DESCRIPTION,
      isSelected: false,
      icon: Shock,
      type: 'ai.duclo.ShockDetection',
      eventClass: [
        Utils.getNotificationEventName().shockStart,
        Utils.getNotificationEventName().shockEnd,
      ],
      resource: Utils.getNotificationResources().shock,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_TAMPERING,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_TAMPERING_DESCRIPTION,
      isSelected: false,
      icon: Tampering,
      type: 'ai.duclo.Tampering',
      eventClass: [
        Utils.getNotificationEventName().tamperStart,
        Utils.getNotificationEventName().tamperEnd,
      ],
      resource: Utils.getNotificationResources().tempering,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_DEFOCUS,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_DEFOCUS_DESCRIPTION,
      isSelected: false,
      icon: DefocusIcon,
      type: 'ai.duclo.DefocusDetection',
      eventClass: [
        Utils.getNotificationEventName().defocusStart,
        Utils.getNotificationEventName().defocusEnd,
      ],
      resource: Utils.getNotificationResources().defocus,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_FOG,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_FOG_DESCRIPTION,
      isSelected: false,
      icon: Fog,
      type: 'ai.duclo.FogDetection',
      eventClass: [
        Utils.getNotificationEventName().fogStart,
        Utils.getNotificationEventName().fogEnd,
      ],
      resource: Utils.getNotificationResources().fog,
    },
    // TODO: below event configuration will be used in future to add new events

    // {
    //   name: constants.NOTIFICATION_CREATE_NOTIFICATION_AUDIO,
    //   desc: constants.NOTIFICATION_CREATE_NOTIFICATION_AUDIO_DESCRIPTION,
    //   isSelected: false,
    //   icon: camera,
    //   type: 'ai.duclo.SoundRecognition',
    //   eventClass: [Utils.getNotificationEventName().scream,Utils.getNotificationEventName().explosion,
    //     Utils.getNotificationEventName().gunShot,Utils.getNotificationEventName().glassBreaking],
    //   resource: Utils.getNotificationResources().audio
    // },
    // {
    //   name: constants.NOTIFICATION_CREATE_NOTIFICATION_GEBS,
    //   desc: constants.NOTIFICATION_CREATE_NOTIFICATION_GEBS_DESCRIPTION,
    //   isSelected: false,
    //   icon: camera,
    //   type: 'ai.duclo.SoundRecognition',
    //   eventClass: ['scream', 'explosion', 'gun-shot', 'glass-breaking'],
    //   resource: Utils.getNotificationResources().sound,
    // },
  ]);

  const [customTriggerList, setCustomTriggerList] = useState([
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_MOTION,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_MOTION_DESCRIPTION,
      isSelected: false,
      icon: MotionDetection,
      type: 'ai.duclo.MotionDetected',
      eventClass: ['motion-start', 'motion-end'],
      resource: Utils.getNotificationResources().motion,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_LINE_CROSSING,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_LINE_CROSSING_DESCRIPTION,
      isSelected: false,
      icon: LineCrossing,
      type: 'ai.duclo.virtualLineDetected',
      eventClass: [
        Utils.getNotificationEventName().virtualLineLeft,
        Utils.getNotificationEventName().virtualLineRight,
        Utils.getNotificationEventName().virtualLineBoth,
      ],
      resource: Utils.getNotificationResources().virtualLine,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_INTRUSION,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_INTRUSION_DESCRIPTION,
      isSelected: false,
      icon: Intrusion,
      type: 'ai.duclo.Intrusion',
      eventClass: [Utils.getNotificationEventName().intrusion],
      resource: Utils.getNotificationResources().intrusion,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_APPEAR_DISAPPEAR,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_APPEAR_DISAPPEAR_DESCRIPTION,
      isSelected: false,
      icon: appearDisappear,
      type: 'ai.duclo.Appear/Disappear',
      eventClass: [Utils.getNotificationEventName().appearDisappear],
      resource: Utils.getNotificationResources().appearDisappear,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_ENTER,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_ENTER_DESCRIPTION,
      isSelected: false,
      icon: Enter,
      type: 'ai.duclo.Enter',
      eventClass: [Utils.getNotificationEventName().vaEnter],
      resource: Utils.getNotificationResources().enter,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_EXIT,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_EXIT_DESCRIPTION,
      isSelected: false,
      icon: Exit,
      type: 'ai.duclo.Exit',
      eventClass: [Utils.getNotificationEventName().vaExit],
      resource: Utils.getNotificationResources().exit,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_LOITERING,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_LOITERING_DESCRIPTION,
      isSelected: false,
      icon: LoiteringCustomEvent,
      type: 'ai.duclo.Loitering',
      eventClass: [Utils.getNotificationEventName().loitering],
      resource: Utils.getNotificationResources().loitering,
    },
  ]);

  useEffect(() => {
    if (Object.keys(getStepsData()).length != 0) {
      if (getStepsData()?.selectedEvents?.length > 0) {
        const selectedList = getStepsData()?.selectedEvents;
        setSelectedEvents(selectedList);
        callFunction(selectedList);
      }
    }
    if (!props?.isEdit) {
      props?.setNotificationData([]);
    }
  }, []);

  const callFunction = async (selectedList) => {
    const list = (
      props?.eventType === 'customEvent' ? customTriggerList : triggerList
    )?.map((item, index) => {
      let isExist = false;
      selectedList?.map((selectedItem, i) => {
        if (item?.type == selectedItem?.type) {
          isExist = true;
        }
      });
      if (isExist) {
        return { ...item, isSelected: true };
      } else {
        return { ...item };
      }
    });
    let triggerEvents = await Promise.all(list);
    props?.eventType === 'customEvent'
      ? setCustomTriggerList(triggerEvents)
      : setTriggerList(triggerEvents);
  };

  const onHandleChange = async (e, item) => {
    if (e != undefined) {
      let updateTrigger = (
        props?.eventType === 'customEvent' ? customTriggerList : triggerList
      )?.map((events, index) => {
        if (Utils.areEqual(events?.eventClass, item?.eventClass)) {
          if (events?.isSelected) {
            return { ...events, isSelected: false };
          } else {
            return { ...events, isSelected: true };
          }
        } else {
          if (props?.eventType === 'customEvent') {
            return { ...events, isSelected: false };
          } else {
            return { ...events };
          }
        }
      });
      let list = await Promise.all(updateTrigger);
      if (props?.eventType === 'customEvent') {
        props?.setCustomLocationId('');
        props?.setVirtualAreasData([]);
        setCustomTriggerList(list);
      } else {
        setTriggerList(list);
      }
      const selectedEvents = list.filter((device) => device?.isSelected);
      setSelectedEvents(selectedEvents);
    }
  };

  const createTriggerData = () => {
    const triggers = [];
    const orEvent = {
      type: 'op',
      logic: 'or',
    };
    selectedEvents?.map((events, index) => {
      if (index !== 0 || index === 6) {
        triggers.push(orEvent);
      }
      const item = {
        type: events?.type,
        properties: {
          eventClass: events?.properties?.eventClass
            ? events?.properties?.eventClass
            : events?.eventClass,
          ...(events?.internalEvent?.length > 0 && {
            internalEvent: events?.internalEvent,
          }),
        },
      };
      triggers.push(item);
    });
    const stepData = {};
    if (props?.isEdit) {
      if (selectedEvents[0]?.type === getStepsData()?.triggers[0]?.type) {
        stepData['triggers'] = getStepsData()?.triggers;
        props?.setVirtualLineUuid(getStepsData()?.triggers[0]?.properties?.ids);
      } else {
        stepData['triggers'] = triggers;
        props?.setVirtualLineUuid([]);
      }
      stepData['selectedEvents'] = selectedEvents;
      stepData['editItem'] = getStepsData()?.editItem;
      stepData['devices'] = getStepsData()?.devices;
      stepData['conditions'] = getStepsData()?.conditions;
      stepData['actions'] = getStepsData()?.actions;
      stepData['subscribers'] = getStepsData()?.subscribers;
      stepData['locationId'] = getStepsData()?.locationId;
    } else {
      stepData['triggers'] = triggers;
      stepData['selectedEvents'] = selectedEvents;
    }
    setStepData(stepData);
    props.nextStep(stepData);
  };

  return (
    <div className="create-notification">
      <StepIndicator {...props} />
      <div className="trigger-type-step-two">
        <div className="step-title">
          {constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER}
        </div>
        <div className="step-title-desc">
          {props?.eventType === 'customEvent'
            ? constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER_DESC_CUSTOM_EVENT
            : constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER_DESC}
        </div>
        {(props?.eventType === 'customEvent'
          ? customTriggerList
          : triggerList
        )?.map((item, index) => {
          return (
            <div
              key={item?.properties?.eventClass}
              className="trigger-event-conatiner"
            >
              <div className="left-container">
                <img className="event-icon" alt="" src={item.icon}></img>
                <div className="title-discription-conatiner">
                  <div className="event-title">{item.name}</div>
                  <div className="event-discription">{item.desc}</div>
                </div>
              </div>

              <input
                checked={item.isSelected}
                name={
                  props?.eventType === 'customEvent' ? 'customEvent' : item.name
                }
                className={
                  props?.eventType === 'customEvent'
                    ? 'input-radio-events-trigger'
                    : 'input-check-events'
                }
                type={props?.eventType === 'customEvent' ? 'radio' : 'checkbox'}
                onChange={(e) => onHandleChange(e, item)}
              />
            </div>
          );
        })}
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={selectedEvents?.length === 0}
          onClick={() => createTriggerData()}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Two;
