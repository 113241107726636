import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HiOutlineX } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  Header,
  SiteToast,
  SiteModal,
  PrimaryButton,
} from '../../components/common';
import { EntitlementsTypeEnum, Utils, constants } from '../../helpers';
import useEventsStore from '../../store/EventsStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import useFiltersStore from '../../store/FiltersStore';
import useEventSelectionStore from '../../store/EventSelectionStore';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import {
  getDeviceInformation,
  setDeviceInformation,
  getAllMqttDataFromResponse,
  resetAllMqttDataFromResponse,
  setKeepAliveRequest,
  getKeepAliveRequest,
  getSubscribeAreasRequest,
  setSubscribeAreasRequest,
} from '../../store/reducers/AccountReducer';
import {
  getPlatformInfo,
  getWSSConnection,
  getCDNInfo,
  setCVRStartDate,
  setChannelTimeStamp,
  setLiveCameraIds,
  setLiveStream,
  setLoadingStream,
  setMetaData,
  setRemoteStream,
  setStartDate,
  setEndOfStream,
  getMQTTConnection,
  setPlatformInfo,
  getIsActiveStream,
  getNoSnapshotError,
  setNoSnapshotError,
  setSnapShoDownloadLoading,
} from '../../store/reducers/StreamingReducer';

import TimelineControls from '../../components/streaming/TimelineControls';
import {
  disconnectWithMQTT,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../utils/connection/mqttConnection';
import EventsList from './EventsList';

import './CameraDashboard.scss';
import {
  checkWSSConnection,
  connectWithWebSocket,
  disconnectWithWebSocket,
  sendPauseCVR,
  sendRegisterCVR,
} from '../../utils/connection/wssConnection';
import { getCustomerOrgDevices } from '../../store/CustomerOrgDevicesStoreIDB';
import {
  getAllDevicesData,
  setAllDevicesData,
} from '../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { findTimeZoneFromSelectedValue } from '../../helpers/commonUtils';
import { useIdleTimer } from 'react-idle-timer';
import { RxCross1 } from 'react-icons/rx';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { setSnapshotImage } from '../../store/StreamingStoreIDB';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';
import { setIsOnDeviceWallPage } from '../../store/reducers/ViewsReducer';

const CameraDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let fullscreenRef = useRef(0);
  const [showFetchImageGrid, setShowFetchImageGrid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(null);
  const [date, setDate] = useState(null);
  const [isLiveStream, setIsLiveStream] = useState(true);
  const [internalEventFromSearch, setInternalEventFromSearch] = useState(false);
  const [showFilteredTitle, setShowFilteredTitle] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [categoryDashboard, setCategoryDashboard] = useState([
    'objectclass',
    'soundrecognition',
  ]);
  const [eventTimestamp, setEventTimestamp] = useState(null);
  const [listHeight, setListHeight] = useState('100%');
  const [deviceLocalTimezone, setDeviceLocalTimezone] = useState('');
  const deviceDetails = useSelector(getDeviceInformation);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const isStreamActive = useSelector(getIsActiveStream);
  const isSnapError = useSelector(getNoSnapshotError);
  const deviceId = location?.state?.id
    ? location?.state?.id
    : deviceDetails?.deviceId;
  const handle = useFullScreenHandle();
  const { setDeviceTimezone } = useEventsStore();
  const { getAppliedTags, setAppliedTags } = useFiltersStore();
  const dispatch = useDispatch();
  const { getEventCVRMode } = useEventsStore();
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const selectedEvent = useEventsStore((state) => state.selectedTimestamp);
  const eventFromSearch = useEventsStore((state) => state.eventFromSearch);
  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();
  const { getSnapshotCoordinates } = useEventsStore();
  const wssConnection = useSelector(getWSSConnection);
  const getPlatformDetails = useSelector(getPlatformInfo);
  const [hubDetails, setHubDetails] = useState(null);
  const [digitalZoomScale, setDigitalZoomScale] = useState(1);
  const [customerOrgDevices, setCustomerOrgDevices] = useState([]);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const [entitleData, setEntitleData] = useState(null);

  const accountId = loggedInUserData.accountId;
  const eventsData = useSelector(getLatestEventsForDots);
  const deviceMetaData = useSelector(getMetaDataForEvents);
  const { setSelectedRegion, setRegion } = useEventsStore();
  const {
    setSnapshotCoordinate,
    setSelectedEventStore,
    setEventCVRMode,
    setEventFromSearch,
  } = useEventsStore();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const { setNavigatedFromCameraDetail } = useEventSelectionStore();
  const MQTTConnectionStatus = useSelector(getMQTTConnection);
  let keepAliveTimer = null;
  const isTimerRunning = useRef(false);
  const keepAlive = useSelector(getKeepAliveRequest);
  const subscribedForLEvents = useSelector(getSubscribeAreasRequest);
  const inactivityPauseTimeInMin =
    getPlatformDetails?.timeline_server?.inactivityPauseTimeInMin;
  const [timerValue, setTimerValue] = useState(
    inactivityPauseTimeInMin ? inactivityPauseTimeInMin * 60000 : 300000,
  );
  const [inactiveModal, setInactiveModal] = useState(false);
  const [activityStatus, setActivityStatus] = useState(false);
  const [stateActivity, setStateActivity] = useState('Active');
  const [remaining, setRemaining] = useState(-1);
  const [liveSnapshotImg, setLiveSnapshotImg] = useState();
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const cdnInfo = useSelector(getCDNInfo);
  const [isLicenseExpired, setIsLicenseExpired] = useState(true);
  const [lastMetaTimeStamp, setLastMetaTimeStamp] = useState();
  const { setDeviceSnapshots } = useDeviceSnapshots();
  const [selectedEventId, setSelectedEventId] = useState('');
  const interval = useRef();
  const isEntitlementDataFetched = useRef(false);
  const onIdle = () => {
    setStateActivity('Idle');
  };

  const onActive = () => {
    setStateActivity('Active');
  };
  const { getRemainingTime, reset, start, pause } = useIdleTimer({
    onIdle,
    onActive,
    timeout: timerValue,
    throttle: 500,
    startOnMount: false, // Don't start the timer automatically on mount
  });

  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;

  // useEffect(() => {
  //   const handleUpdate = async (data) => {
  //     if (data.key === 'allMqttDataFromResponse') {
  //       await loadAllMqttData();
  //     }
  //   };
  //   observerInstance.addObserver(handleUpdate);
  //   loadAllMqttData();

  //   return () => {
  //     observerInstance.removeObserver(handleUpdate);
  //   };
  // }, [
  //   loadAllMqttData,
  // ]);
  // const getTimesinmili = () => {
  //   const time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds();
  //   return time;
  // }
  useEffect(() => {
    console.log(
      'Livestream camera detail entered',
      new Date().getHours() +
        ':' +
        new Date().getMinutes() +
        ':' +
        new Date().getSeconds() +
        ':' +
        new Date().getMilliseconds(),
    );
    setSnapIniitiall();
    getSnapShotImageFromCache();
  }, []);

  useEffect(() => {
    if (lastMetaTimeStamp) getSnapshotImageForLastMetaEnd();
  }, [lastMetaTimeStamp]);

  const getSnapShotImageFromCache = () => {
    if (deviceSnapshots?.[deviceId]) {
      setLiveSnapshotImg(deviceSnapshots?.[deviceId]);
    }
  };

  const getSnapshotImageForLastMetaEnd = () => {
    const lastMetaEndTime = Utils.getUnixDate(
      moment(lastMetaTimeStamp).toDate(),
    );
    const bucket = (cdnInfo?.bucket).replace('${deviceId}', deviceId);
    const date = Utils.fetchDateInUnix(lastMetaEndTime);
    if (deviceId) {
      fetch(
        `${cdnInfo?.protocol}://${cdnInfo?.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`,
        {
          credentials: 'include',
        },
      )
        .then((response) => response.blob())
        .then((blob) => {
          setLiveSnapshotImg(URL.createObjectURL(blob));
          setDeviceSnapshots(
            deviceId,
            `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`,
          );
        })
        .catch(() => {
          Utils.vmsLogger().log('Error got', deviceId);
        });
    }
  };

  const setSnapIniitiall = async () => {
    await setSnapshotImage({});
  };

  useEffect(() => {
    if (
      MQTTConnectionStatus &&
      Array.isArray(subscribeTopic) &&
      subscribeTopic.length > 0
    ) {
      const deviceSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic[0],
          '${deviceId}',
          `${deviceDetails?.gatewayId}`,
        ),
        qos: 0,
      };
      mqttSubscribe(deviceSubscription);

      return () => {
        mqttUnsubscribe(deviceSubscription);
      };
    }
  }, [deviceDetails, MQTTConnectionStatus]);

  useEffect(() => {
    if (remaining === 0 && stateActivity == 'Idle') {
      // Reset timer with 5 minutes timeout again
      pause();
      setInactiveModal(true);
      setActivityStatus(true);
    }
  }, [remaining]);

  const handleInactivePopUp = (e) => {
    e.preventDefault();
    setActivityStatus(false);
    if (!isLiveStream) {
      pause();
    } else {
      reset({ timeout: timerValue }); // Reset timer with 5 minutes timeout again
    }
    setInactiveModal(false);
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  useEffect(() => {
    if (isStreamActive) {
      start();
      interval.current = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [isStreamActive]);

  useEffect(() => {
    if (!isLiveStream) {
      pause();
    }
  }, [isLiveStream]);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );
  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const handleClick = (detail) => {
    if (detail === constants.DEVICES_SWITCH_TIMELINE_TITLE) {
      setShow(constants.DEVICES_SWITCH_TIMELINE_TITLE);
    } else if (detail === constants.DEVICES_SWITCH_LIVE_TITLE) {
      dispatch(setRemoteStream(null));
      // Commented because double WebRTCConnections are creating. When redirecting or reloading Camera details page.
      // publishWithMQTT(
      //   platformDetails.mqtt,
      //   platformDetails.p2p_server,
      //   deviceDetails,
      //   accountId
      // );
    }
  };

  useEffect(() => {
    if (isSnapError !== '') {
      showErrorToaster(isSnapError);
    }
  }, [isSnapError]);

  const showErrorToaster = (mesg) => {
    setShowToast(true);
    setUserMsg(mesg);
    setTimeout(() => {
      setShowToast(false);
      setUserMsg('');
      if (isSnapError !== '') dispatch(setNoSnapshotError(''));
    }, 5500);
  };

  useEffect(() => {
    const filters = getAppliedTags();
    setShowFilteredTitle(filters && filters.length > 0 ? true : false);
  }, [getAppliedTags()]);

  useEffect(() => {
    if (
      deviceId &&
      wssConnection &&
      checkWSSConnection() &&
      orgDetails?.orgId
    ) {
      checkTokenSendWebSocketRequest();
    }
  }, [deviceId, wssConnection, orgDetails?.orgId]);

  useEffect(() => {
    if (!wssConnection && getPlatformDetails) {
      connectWithWebSocket(getPlatformDetails, deviceId);
    }
  }, [wssConnection, getPlatformDetails]);

  useEffect(() => {
    dispatch(setKeepAliveRequest(false));
    dispatch(setSubscribeAreasRequest(false));
    isTimerRunning.current = true;
    return () => {
      sendPauseCVR(deviceId);
      disconnectWithWebSocket();
      setAppliedTags('');
      isTimerRunning.current = false;
      dispatch(setKeepAliveRequest(false));
      dispatch(setSubscribeAreasRequest(false));
      if (deviceDetails) {
        deleteRequest(deviceDetails);
      }
      clearTimeout(keepAliveTimer);
    };
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();
    setAppliedTags('');
  }, []);

  useEffect(() => {
    let device = null;
    if (
      MQTTConnectionStatus &&
      devicesDetails.length &&
      !subscribedForLEvents &&
      !keepAlive &&
      deviceId
    ) {
      device = devicesDetails?.find((device) => device.deviceId === deviceId);
      if (device) subscribeRequest(device);
    }
  }, [
    MQTTConnectionStatus,
    devicesDetails,
    subscribedForLEvents,
    keepAlive,
    deviceId,
  ]);

  const checkTokenSendWebSocketRequest = () => {
    const token_expiry = getPlatformDetails?.timeline_server?.expiry;
    if (token_expiry < new Date() / 1000) {
      axios
        .get(`/partner/platform/v2`, {
          withCredentials: true,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            const result = Utils.transformBandwidthPlans(response?.data?.data);
            dispatch(setPlatformInfo(result));
            // if (!checkMQTTConnection() && accountIdRes) {
            //   connectWithMQTT(accountIdRes);
            // }
            const updated_token = response?.data?.data?.timeline_server?.token;
            sendRegisterCVR(
              deviceId,
              accountId,
              orgDetails?.orgId,
              updated_token,
              deviceDetails?.deviceName,
            );
          }
        });
    } else {
      const token = getPlatformDetails?.timeline_server?.token;
      sendRegisterCVR(
        deviceId,
        accountId,
        orgDetails?.orgId,
        token,
        deviceDetails?.deviceName,
      );
    }
  };

  const subscribeRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }

    dispatch(setSubscribeAreasRequest(true));
    Utils.vmsLogger().log('add request subscribe', device.deviceId);
    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'add',
          properties: {
            sessionId: sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (!keepAlive) {
      keepAliveSetTimer(device);
    }
  };

  const keepAliveSetTimer = (device) => {
    //Retrieve keep alive session every 90 seconds
    keepAliveTimer = setTimeout(() => {
      if (isTimerRunning.current) {
        keepAliveRequest(device);
      }
    }, 90000);
  };

  const keepAliveRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }
    dispatch(setKeepAliveRequest(true));
    Utils.vmsLogger().log('keep alive request2', device.deviceId);

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (isTimerRunning.current) {
      keepAliveSetTimer(device);
    }
  };

  const deleteRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device?.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device?.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device?.deviceId}/camera/events/live-events`,
          action: 'delete',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  useEffect(() => {
    if (Utils.isPageReloaded()) {
      setSelectedEventStore('null');
    }
    setEventFromSearch(false);
    dispatch(setChannelTimeStamp(false));
    dispatch(setEndOfStream(false));
    dispatch(setSnapShoDownloadLoading(false));

    const handleResize = () => {
      let fullscreenEl = fullscreenRef.current;

      if (!fullscreenEl) {
        return;
      }

      setListHeight(
        fullscreenEl.querySelector('.fullscreen')?.clientHeight - 4 + 'px',
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    dispatch(setLiveCameraIds([]));

    return () => {
      sendPauseCVR(deviceId);
      disconnectWithWebSocket();
      window.removeEventListener('resize', handleResize);
      disconnectWithMQTT();
      setSelectedEventStore('null');
      setEventCVRMode('null');
      dispatch(setChannelTimeStamp(false));
      setSelectedRegion(null);
      setSnapshotCoordinate({});
      setRegion([]);
      dispatch(setCVRStartDate(''));
      dispatch(setRemoteStream(null));
      if (!Utils.isPageReloaded()) {
        dispatch(setDeviceInformation(null));
      }
    };
  }, []);

  useEffect(() => {
    setInternalEventFromSearch(eventFromSearch);
  }, [eventFromSearch]);

  useEffect(() => {
    if (devicesDetails?.length > 0 && deviceId) {
      const deviceIndex = devicesDetails?.find(
        (device) => device.deviceId === deviceId,
      );
      dispatch(setDeviceInformation(deviceIndex));
      getCapabiltiesForDevice(deviceIndex);
      dispatch(setLoadingStream(true));
      dispatch(setLiveStream(null));
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));
      //handleClick('LIVE');
      //subscribeRequest(deviceIndex?.areaId);

      if (state.getAccountId() !== accountId) {
        state.setAccountId(accountId);
      }

      if (!state.getSessionId()) {
        state.setSessionId(uuidv4());
      }

      const subscribeForMetaData = {
        topic: `b/streams/${deviceId}`,
        qos: 0,
      };

      const subscribeForEvents = {
        topic: `d/rt-events/${deviceIndex?.gatewayId}`,
        qos: 0,
      };

      // Subscribe to the app topic
      mqttSubscribe(subscribeForMetaData);
      // Subscribe  to the device topic
      mqttSubscribe(subscribeForEvents);

      return () => {
        mqttUnsubscribe(subscribeForMetaData);
        mqttUnsubscribe(subscribeForEvents);
        dispatch(resetEvents);
        disconnectWithMQTT();
      };
    }
  }, [deviceId, JSON.stringify(devicesDetails)]);

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          dispatch(
            setDeviceInformation({ ...deviceData, capDetails: responseJson }),
          );
        } else {
          dispatch(setDeviceInformation(deviceData));
        }
      } catch (error) {
        dispatch(setDeviceInformation(deviceData));
      }
    } else {
      dispatch(setDeviceInformation(deviceData));
    }
  };

  useEffect(() => {
    dispatch(setIsOnDeviceWallPage(false));
    const fetchDevices = async () => {
      const devices = await getCustomerOrgDevices();
      setCustomerOrgDevices(devices);
    };
    fetchDevices();
    return () => {
      isEntitlementDataFetched.current = false;
    };
  }, []);

  useEffect(() => {
    // if (deviceDetails) {
    // dispatch(setCVRStartDate(''));
    const url = deviceDetails?.entitlement?.url;
    if (url && !isEntitlementDataFetched.current) {
      isEntitlementDataFetched.current = true;
      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const expiryDate = data?.term?.expiry;
            const durationInDays = data?.term?.data?.durationInDays;
            const startDate =
              expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
            setDate(startDate);
            dispatch(setStartDate(startDate));
            const cloudStorageCVR = data.entitlements?.find(
              (item) => item.type === EntitlementsTypeEnum.CLOUD_STORAGE_CVR,
            );
            if (cloudStorageCVR && cloudStorageCVR?.data?.durationInDays) {
              const cvrStartDate =
                Date.parse(new Date()) -
                cloudStorageCVR.data?.durationInDays * 24 * 60 * 60 * 1000;
              dispatch(setCVRStartDate(cvrStartDate));
            }
            const checkLicense = data.entitlements?.find(
              (entitle) =>
                entitle.type === EntitlementsTypeEnum.LIVE_VIEW &&
                entitle.expiry < Utils.getUnixDate(new Date()) * 1000,
            );
            if (checkLicense) {
              setIsLicenseExpired(true);
            } else {
              setIsLicenseExpired(false);
            }
            setEntitleData(data?.entitlements);
          }
        });
    } else {
      if (!url) setIsLicenseExpired(true);
    }
    // }
    // const customerOrgDevices = await getCustomerOrgDevices();
  }, [deviceDetails?.entitlement?.url]);

  useEffect(() => {
    if (deviceDetails?.gatewayId && customerOrgDevices?.length > 0) {
      const parentDevices = Utils.getTotalParentDevices(customerOrgDevices);
      const parentDevice = parentDevices.find(
        (device) => device.gatewayId === deviceDetails?.gatewayId,
      );
      setHubDetails(parentDevice);
      setDeviceLocalTimezone(parentDevice?.properties?.timezone);
      setTimezone(parentDevice?.properties?.timezone);
      const getLocationSelected = findTimeZoneFromSelectedValue(
        parentDevice?.properties?.timezone,
      );
      const zone = getLocationSelected?.location || moment.tz.guess();
      setDeviceTimezone(zone);
    }
  }, [deviceDetails?.gatewayId, customerOrgDevices?.length]);

  useEffect(() => {
    if (eventCategory.length > 0) {
      setCategoryDashboard(eventCategory);
    }
  }, [eventCategory]);

  useEffect(() => {
    setEventTimestamp(selectedEvent);
  }, [selectedEvent]);

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (resource === 'camera/system/ptz' && deviceId === responseDeviceId) {
      let ptzprops = JSON.parse(JSON.stringify(allMqttData?.msg?.properties));
      if (
        allMqttData?.msg?.properties?.['ptz-pt'] === 'stop' ||
        allMqttData?.msg?.properties?.['ptz-zoom-reset']
      ) {
        ptzprops['ptz-zoom'] = Math.random();
      }
      updateDeviceStore(ptzprops, true, allMqttData?.msg?.action !== 'error');
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
    let deficeInfoProperty = openDeviceDetails?.properties;
    mqttStatus &&
      localStorage.setItem(
        'localStoreProperty',
        JSON.stringify({ ...deficeInfoProperty }),
      );
    let localStoreProperty = JSON.parse(
      localStorage.getItem('localStoreProperty'),
    );
    deficeInfoProperty = mqttStatus
      ? { ...deficeInfoProperty, ...properties }
      : localStoreProperty && {
          ...deficeInfoProperty,
          ...localStoreProperty,
        };
    openDeviceDetails = {
      ...openDeviceDetails,
      properties: deficeInfoProperty,
    };
    let updatedList = devicesDetails?.map((alldevice, index) => {
      if (alldevice.deviceId === openDeviceDetails.deviceId) {
        return {
          ...openDeviceDetails,
        };
      } else {
        return { ...alldevice };
      }
    });
    let dList = await Promise.all(updatedList);
    await setAllDevicesData(dList);
    dispatch(setDeviceInformation(openDeviceDetails));
    isFromMqtt && (await resetAllMqttDataFromResponse());
  };

  return (
    <div className="App video-wall">
      <Header showCart={false} currentDeviceId={deviceId} />
      <div className="main-wrapper">
        <div className="video-wall-background">
          <div className="custom-container-fluid">
            <div className="page-header mt-4">
              <Row>
                <div className="toast-wrapper">
                  <SiteToast
                    customCss="licenses-list-toast"
                    position="top-end"
                    show={showToast}
                    title="Uh-oh!"
                    body={userMsg}
                    delay={5000}
                  />
                  <div>
                    <div className="device-title">
                      {deviceDetails?.deviceName}
                      {showFilteredTitle && (
                        <span className="filtered-title"> (filtered)</span>
                      )}
                    </div>
                    <div className="device-sub-title">
                      {deviceDetails?.locationAreaNames}
                    </div>
                  </div>
                  <div className="button-nav">
                    <Button
                      className="button-nav-item close-button"
                      onClick={() => {
                        sendPauseCVR(deviceId);
                        disconnectWithWebSocket();
                        setNavigatedFromCameraDetail(true);
                        navigate(-1);
                      }}
                    >
                      <HiOutlineX size={16} className="close-icon" />{' '}
                      {constants.CLOSE_BUTTON}
                    </Button>
                  </div>
                </div>
              </Row>
              <div className="cameras-container video-detail-wrapper">
                <Row className="devices-row">
                  <Col md={9} lg={9} xl={9} xs={12} ref={fullscreenRef}>
                    <FullScreen handle={handle}>
                      <div className="timeline-controls-main-container">
                        {deviceId && entitleData && (
                          <TimelineControls
                            activityStatus={activityStatus}
                            internalEventFromSearch={eventFromSearch}
                            category={categoryDashboard}
                            eventTimestamp={selectedEvent}
                            eventCVRMode={getEventCVRMode()}
                            timezone={deviceLocalTimezone}
                            startDate={date}
                            deviceId={deviceId}
                            hubId={deviceDetails?.gatewayId}
                            hubDetails={hubDetails}
                            enterFullScreen={() => handle.enter()}
                            exitFullScreen={() => handle.exit()}
                            handleClick={handleClick}
                            customMetaData={deviceMetaData}
                            eventDotsData={eventsData}
                            showFetchImageGrid={showFetchImageGrid}
                            setIsLiveStream={(value) => setIsLiveStream(value)}
                            isLiveStream={isLiveStream}
                            setHideFetchImageGrid={() =>
                              setShowFetchImageGrid(false)
                            }
                            sessionId={state.getSessionId()}
                            snapshotCoordinates={getSnapshotCoordinates()}
                            appliedFilter={getAppliedTags()}
                            showErrorToaster={showErrorToaster}
                            setDigitalZoomScale={(value) =>
                              setDigitalZoomScale(value)
                            }
                            liveSnapshotImg={liveSnapshotImg}
                            setLastMetaTimeStamp={setLastMetaTimeStamp}
                            isLicenseExpired={isLicenseExpired}
                            selectedEventId={selectedEventId}
                          />
                        )}
                      </div>
                    </FullScreen>
                  </Col>
                  <Col
                    className="events-list-panel"
                    md={3}
                    lg={3}
                    xl={3}
                    xs={12}
                  >
                    <div className="events-list-wrapper">
                      <div className="event-list-container">
                        {deviceId && (
                          <EventsList
                            key={deviceId}
                            deviceId={deviceId}
                            listHeight={listHeight}
                            showFetchImageGrid={() =>
                              setShowFetchImageGrid(true)
                            }
                            hideFetchImageGrid={() =>
                              setShowFetchImageGrid(false)
                            }
                            showGridOnImage={showFetchImageGrid}
                            digitalZoomScale={digitalZoomScale}
                            isLiveStream={isLiveStream}
                            selectedEventId={selectedEventId}
                            setSelectedEventId={(eventId) =>
                              setSelectedEventId(eventId)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={inactiveModal}
        hideModal={(e) => handleInactivePopUp(e)}
      >
        <div className="inactive-modal">
          <div className="steps-header-conatiner">
            <div className="header-container">
              <div className="left-section-layout">
                <div className="header-title-inactive">
                  {constants.INACTIVITY_MODAL_MESSAGE}
                </div>
              </div>
              <RxCross1 size={24} onClick={(e) => handleInactivePopUp(e)} />
            </div>
          </div>
          {
            <PrimaryButton
              className="btn btn-outline-danger mt-4"
              type="button"
              height="44px"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_primary')}
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_primary')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_32')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_32')}
              onClick={(e) => {
                handleInactivePopUp(e);
              }}
            >
              {constants.INACTIVITY_MODAL_CONTINUE_BUTTON_LABEL}
            </PrimaryButton>
          }
        </div>
      </SiteModal>
    </div>
  );
};

export default CameraDashboard;
