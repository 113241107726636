import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  SelectField,
  ToggleInput,
  TextField,
  InputSlider,
} from '../../../../components/forms';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { Formik, Field } from 'formik';
import {
  AppDefaults,
  constants,
  EntitlementsTypeEnum,
  LicenseStatuses,
  OfferingTypeEnum,
  Utils,
} from '../../../../helpers';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './DeviceSettingsBlock.scss';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import { getAllMqttDataFromResponse } from '../../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import { usePoliciesStore } from '../../../../store/policiesStore';
import { MdOutlineEdit } from 'react-icons/md';
import { Plusred } from '../../../../assets/images';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import ReactSlider from 'react-slider';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { devicesMQTTStore } from '../../../../store/DevicesMQTTStore';
import { mqttPublish } from '../../../../utils/connection/mqttConnection';
import useLicensesStore from '../../../../store/LicensesStore';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';
import axios from 'axios';
const DeviceSettingsBlock = ({
  orgDetails,
  orgId,
  orgName,
  deviceId,
  deviceName,
  saveDeviceSettingsFlag,
  saveDeviceSettingsCallback,
  deviceInfos,
  accountId,
  updateStore,
  devicePublishId,
  uuid,
  hasManagePermission,
  deviceFPS,
  deviceRes,
  parent,
  deviceBitRate,
}) => {
  const formRef = useRef();
  const orgInfo = useSelector(getOrgInfo);
  const [variant, setVariant] = useState('danger');
  const [deviceBitrate, setDeviceBitRate] = useState(deviceBitRate);
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(true);
  const [fpsReuest, setFpsReuest] = useState(false);
  const [payloadProperty, setPayloadProperty] = useState({});
  const [pendingValue, setPendingValue] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [selectedFrameRate, setSelectedFrameRate] = useState(null);
  const [allBrs, setAllBrs] = useState([]);
  const [isEditMode, setIsEditMode] = useState(null);
  const [NetworkContinueModal, setNetworkContinueModal] = useState(false);
  const [kbpsValue, setkbpsValue] = useState();
  const [daysList, setDaysList] = useState(Utils.getWeekDays(['0']));
  const [selectedIds, setSelectedIds] = useState([]);
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const { licenses, getLicenses, setLicenses, setIslicensesAttachStatus } =
    useLicensesStore();
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [selectedDaysValues, setSelectedDaysValues] = useState([]);
  const defalutDateStart = new Date();
  defalutDateStart.setHours(0);
  defalutDateStart.setMinutes(0);
  defalutDateStart.setSeconds(0);
  const defalutDateEnd = new Date();
  defalutDateEnd.setHours(0);
  defalutDateEnd.setMinutes(5);
  defalutDateEnd.setSeconds(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(5);
  const [endTimeMoment, setEndTimeMoment] = useState(moment(defalutDateEnd));
  const [isOverlaping, setIsOverlaping] = useState(false);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const appTopic = `a/notify/${deviceInfos.gatewayId}`;
  const resourceMaxBr = Utils.getDeviceSettingResource(9);
  const [bitrateToggleStatus, setBitrateToggleStatus] = useState({
    'mbr-enable': true,
  });
  const [startTimeMoment, setStartTimeMoment] = useState(
    moment(defalutDateStart),
  );
  const dayDefault =
    selectedIds?.length > 1 && selectedIds?.length < 7
      ? constants.UPDATE_FIRMWARE_MULTIPLE
      : selectedIds?.length === 7
        ? constants.UPDATE_FIRMWARE_ALL_DAYS
        : selectedIds?.length === 1
          ? Utils.getWeekDays(selectedIds)?.find(
              (day) => day?.id?.toString() === selectedIds[0],
            )?.day
          : 'Sunday';
  const [recordCodecList, setRecordCodecList] = useState([
    { label: 'H264', value: 1 },
  ]);
  const [defaultSelected, setDefaultSelected] = useState(1);
  const [showDeviceRestartModal, setShowDeviceRestartModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const [ConfirmRestartRequiredModal, setConfirmRestartRequiredModal] =
    useState(false);
  const [modalType, setModalType] = useState(1);
  const bitrateProps = {
    'mbr-enable': bitrateToggleStatus['mbr-enable'],
    'mbr-kbps': kbpsValue,
    'mbr-day': selectedIds?.join(','),
    'mbr-st': startTime,
    'mbr-et': endTime,
  };
  const [selectDay, setselectDay] = useState(dayDefault);
  const [isWdrVisible, setIsWdrVisible] = useState(true);
  const [isIrVisible, setIsIrVisible] = useState(true);

  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const resourceList = Utils.getDeviceSettingResource(1);
  const capFlipRotate = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0],
  );
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const deviceDegreeRotation = Utils.getDropDownPair(
    capFlipRotate?.['rotation-angle'],
  )?.map((degree) => ({
    ...degree,
    label: `Hallyway View: ${degree?.label} Degrees`,
  }));
  const capWdrLevel = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1],
  );
  const capWdrEnable = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1],
  );
  const capVideoProfile = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[2],
  );
  const min =
    Number(capVideoProfile?.['bit-rate']?.min) >= 1
      ? Number(capVideoProfile?.['bit-rate']?.min)
      : 0;
  const max = deviceBitrate
    ? deviceBitrate.data.maxValue
    : Number(capVideoProfile?.['bit-rate']?.max);
  const timezone = parent?.properties?.timezone.split('-')?.[0];

  const findHighestResolution = (resolutions) => {
    let maxResolution = null;
    let maxPixels = 0;

    resolutions.forEach((res) => {
      const [width, height] = res.value.split('x').map(Number);
      const pixels = width * height;

      if (pixels > maxPixels) {
        maxPixels = pixels;
        maxResolution = res.value;
      }
    });

    return maxResolution;
  };

  const deviceResolution =
    deviceRes && capVideoProfile?.['mp-resolution']
      ? Utils.getDropDownPairRes(
          capVideoProfile?.['mp-resolution'],
          deviceRes.data.maxValue,
        )
      : capVideoProfile?.['mp-resolution']
        ? Utils.getDropDownPair(capVideoProfile?.['mp-resolution'])
        : [];

  const capWisestream = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[3],
  );
  const capIrMode = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[4],
  );
  const capEnhacement = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[5],
  );

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const navigate = useNavigate();
  const prevFrameRateRef = useRef(formRef?.current?.values?.frameRate);

  useEffect(() => {
    if (
      prevFrameRateRef.current !== undefined &&
      prevFrameRateRef.current !== formRef?.current?.values?.frameRate
    ) {
      setSelectedFrameRate(formRef?.current?.values?.frameRate);
      const property = {
        'minimum-dynamic-fps': Math.floor(
          formRef?.current?.values?.frameRate / 2,
        ).toString(),
      };
      updateStore(property);
      publishDeviceSetting(property, resourceList[2], publishPayload);
    }
    prevFrameRateRef.current = formRef?.current?.values?.frameRate;
  }, [formRef?.current?.values?.frameRate]);

  const fetchData = async (url) => {
    if (!url) return;
    try {
      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const entitlements = data.entitlements;
            if (entitlements) {
              const videoBitRate = entitlements?.find(
                (item) => item.type === EntitlementsTypeEnum.BITRATE,
              );
              if (videoBitRate) setDeviceBitRate(videoBitRate);
            }
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  const getCapabiltiesForDevice = async () => {
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (responseData) {
            const matchedDevices = responseData.filter(
              (device) => device.deviceId === deviceInfos.deviceId,
            );
            fetchData(matchedDevices[0]?.entitlement?.url);
          }
        }
      });
  };

  useEffect(() => {
    getCapabiltiesForDevice();
  }, []);

  useEffect(() => {
    if (formRef?.current && saveDeviceSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveDeviceSettingsFlag]);

  useEffect(() => {
    const noIndex = deviceInfos?.properties?.['max-br']?.find(
      (b) => !b?.['mbr-index'],
    );
    if (
      deviceInfos?.properties?.['max-br'] &&
      JSON.stringify(deviceInfos?.properties?.['max-br']) !==
        JSON.stringify(allBrs) &&
      !noIndex
    )
      setAllBrs(
        deviceInfos?.properties?.['max-br']
          ? deviceInfos?.properties?.['max-br']
          : [],
      );
  }, [deviceInfos?.properties]);

  const getAllBitRates = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  useEffect(() => {
    getAllBitRates();
  }, []);

  useEffect(() => {
    const getDynamicFPSMaxValue = (values) => {
      let value;
      if (
        capVideoProfile?.['minimum-dynamic-fps']?.[values?.deviceResolution]
      ) {
        value =
          capVideoProfile['minimum-dynamic-fps'][values?.deviceResolution]?.max;
      } else {
        value = capVideoProfile?.['minimum-dynamic-fps']?.max;
        const propertyData = allMqttData?.msg?.properties;
        if (
          propertyData?.hasOwnProperty('dynamic-fps-enable') ||
          propertyData?.hasOwnProperty('minimum-dynamic-fps')
        ) {
          setFpsReuest(false);
        }
      }
    };
    getDynamicFPSMaxValue();
  }, [allMqttData]);

  const getDynamicFPSMinValue = (values) => {
    let propertyValue;
    if (capVideoProfile?.['minimum-dynamic-fps']?.[values?.deviceResolution]) {
      propertyValue =
        capVideoProfile['minimum-dynamic-fps'][values.deviceResolution].min;
    } else {
      propertyValue = capVideoProfile?.['minimum-dynamic-fps']?.min;
    }
    return Utils.getPropertyValueIfExist(propertyValue, false);
  };

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  const handleChange = (setFieldValue) => {
    setFieldValue(fieldName, pendingValue);
    updateStore(payloadProperty);
    publishDeviceSetting(payloadProperty, resourceList[0], publishPayload);
  };

  const getSelectedDays = (br) => {
    let days = [];
    br['mbr-day'].split(',')?.forEach((day) => {
      const dayData = Utils.getWeekDays([day])?.find(
        (dayObj) => dayObj?.id === Number(day),
      );
      days.push(dayData?.day);
    });
    return days.join(', ');
  };

  const timeConversion = (time) => {
    const hours = Math.trunc(time / 60);
    const pref = hours > 11 ? 'PM' : 'AM';
    const hr = hours > 12 ? hours - 12 : hours;
    const hbr = hr ? hr : 12;
    const mins = time % 60;
    const finalMin = mins?.toString()?.length === 1 ? `0${mins}` : mins;
    const finalHr = hbr?.toString()?.length === 1 ? `0${hbr}` : hbr;
    return `${finalHr}.${finalMin} ${pref}`;
  };

  const onEditBr = (br) => {
    setIsEditMode(br['mbr-index']);
    const startD = new Date();
    const endD = new Date();
    const dayList = Utils.getWeekDays(br['mbr-day'].split(','));
    startD.setHours(Math.trunc(br['mbr-st'] / 60));
    startD.setMinutes(Math.trunc(br['mbr-st'] % 60));
    endD.setHours(Math.trunc(br['mbr-et'] / 60));
    endD.setMinutes(Math.trunc(br['mbr-et'] % 60));
    daysList?.forEach((days) => {
      selectedDay(days);
    });
    setDaysList(dayList);
    setStartTime(br['mbr-st']);
    setEndTime(br['mbr-et']);
    setStartTimeMoment(moment(startD));
    setEndTimeMoment(moment(endD));
    setkbpsValue(br['mbr-kbps']);
    setBitrateToggleStatus({ 'mbr-enable': br['mbr-enable'] });
    setNetworkContinueModal(true);
  };
  const resetProps = () => {
    setDaysList(Utils.getWeekDays(['0']));
    setStartTime(0);
    setEndTime(5);
    setkbpsValue(min);
    setStartTimeMoment(moment(defalutDateStart));
    setEndTimeMoment(moment(defalutDateEnd));
    setselectDay(dayDefault);
    setSelectedDaysValues([]);
    setSelectedIds([]);
    setBitrateToggleStatus({ 'mbr-enable': true });
    setModalType(1);
  };

  const formatValue = (value) => {
    if (value < 1024) {
      return `${value} ${constants.KBPS}`;
    } else {
      return `${(value / 1024).toFixed(1)} ${constants.MBPS}`;
    }
  };

  const setStartEndTime = (epochValue, isTimeStart) => {
    if (isNaN(epochValue)) return;
    const date = new Date(epochValue);
    const calculatedTime = date.getHours() * 60 + date.getMinutes();
    if (isTimeStart) {
      setStartTime(calculatedTime);
      setStartTimeMoment(epochValue);
    } else {
      setEndTime(calculatedTime);
      setEndTimeMoment(epochValue);
    }
    checkOverlaping(calculatedTime);
  };

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id == data.id) {
        if (data.isSelected && selectedIds?.length != 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  const renderReactSlider = (props, state) => {
    setkbpsValue(state.value);
    return (
      <div {...props}>
        {state?.index === 0 && (
          <span className="range-slider-start-value max-recording-bitrate-style">{`${min} ${constants.KBPS}`}</span>
        )}
        {state?.index === 1 && (
          <span className="range-slider-last-value max-recording-bitrate-style">
            {formatValue(max)}
          </span>
        )}
      </div>
    );
  };

  const commaSeparatedDays = () => {
    return selectedDaysValues.join(', ');
  };

  const checkOverlaping = () => {
    if (!selectedIds.length) {
      return;
    }
    if (startTime >= endTime) {
      setIsOverlaping(true);
      setShowMsg(true);
      return;
    }
    allBrs.map((br) => {
      if (isEditMode !== br['mbr-index']) {
        let isCommonDay = false;
        selectedIds?.forEach((id) => {
          if (br['mbr-day'].includes(id)) {
            isCommonDay = true;
          }
        });

        if (
          isCommonDay &&
          (br['mbr-st'] === startTime ||
            startTime === br['mbr-et'] ||
            (br['mbr-st'] < startTime && startTime < br['mbr-et']))
        ) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (
          isCommonDay &&
          (br['mbr-st'] === endTime ||
            endTime === br['mbr-et'] ||
            (br['mbr-st'] < endTime && endTime < br['mbr-et']))
        ) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (isCommonDay && startTime < br['mbr-st'] && br['mbr-st'] < endTime) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
        if (isCommonDay && startTime < br['mbr-et'] && br['mbr-et'] < endTime) {
          setIsOverlaping(true);
          setShowMsg(true);
        }
      }
    });
  };

  useEffect(() => {
    const dayIds = [];
    const defaultSelectedValue = [];
    daysList &&
      daysList.forEach((days) => {
        if (days.isSelected) {
          dayIds.push(days.id);
          defaultSelectedValue.push(days.shortName);
        }
      });
    if (dayIds?.length > 1 && dayIds?.length < 7) {
      setselectDay(constants.UPDATE_FIRMWARE_MULTIPLE);
    } else if (dayIds?.length === 7) {
      setselectDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
    } else if (dayIds?.length === 1) {
      const day =
        dayIds?.length === 1
          ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])?.day
          : 'Sunday';
      setselectDay(day);
    }
    setSelectedIds(dayIds);
    setUpdateDayStaus(false);
    setSelectedDaysValues(defaultSelectedValue);
  }, [updateDayStaus]);

  useEffect(() => {
    setIsOverlaping(false);
    setShowMsg(false);
    checkOverlaping();
  }, [selectedIds, startTime, endTime]);

  const renderTimePicker = (timeValue, isStart) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          onChange={(newValue) => {
            setStartEndTime(newValue, isStart);
          }}
          value={timeValue}
          renderInput={(params) => <TextField />}
          closeOnSelect={false}
        />
      </LocalizationProvider>
    );
  };

  const deleteMaxStrRequest = () => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          action: 'delete',
          properties: {
            'mbr-index': isEditMode,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    setIsEditMode(null);
    resetProps();
    setNetworkContinueModal(false);
  };

  const preUpdateRequest = () => {
    const newList = JSON.parse(JSON.stringify(allBrs));
    let index = -1;

    allBrs.forEach((br, ind) => {
      if (br['mbr-index'] === isEditMode) {
        index = ind;
      }
    });
    bitrateProps['mbr-index'] = isEditMode;
    newList[index] = bitrateProps;
    setAllBrs(newList);
    updateMaxBRRequest(bitrateProps, newList);
    setConfirmRestartRequiredModal(false);
  };

  const getUpdateConfirmation = () => {
    setModalType(3);
    setNetworkContinueModal(false);
    setConfirmRestartRequiredModal(true);
  };

  const updateMaxBRRequest = (props, newBrs) => {
    setIsEditMode(null);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          properties: props,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    setIsEditMode(null);
    resetProps();
    setNetworkContinueModal(false);
    updateStore({ 'max-br': newBrs });
  };

  const addMaxStrRequest = () => {
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }
    // Send the request
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${deviceInfos.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'add',
          resource: `ch/${deviceId}/${resourceMaxBr[0]}`,
          properties: bitrateProps,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    const props = [
      ...(deviceInfos.properties['max-br']
        ? deviceInfos.properties['max-br']
        : []),
      ...[bitrateProps],
    ];
    resetProps();
    setConfirmRestartRequiredModal(false);
  };

  const getConfirmation = () => {
    setModalType(2);
    setNetworkContinueModal(false);
    setConfirmRestartRequiredModal(true);
  };

  const preUpdateKBPSRequest = (status, br, index) => {
    const newList = JSON.parse(JSON.stringify(allBrs));
    newList[index]['mbr-enable'] = status['mbr-enable'];
    setAllBrs(newList);

    const props = {
      'mbr-index': br?.['mbr-index'],
      'mbr-enable': status?.['mbr-enable'],
      'mbr-kbps': br?.['mbr-kbps'],
      'mbr-day': br?.['mbr-day'],
      'mbr-st': br?.['mbr-st'],
      'mbr-et': br?.['mbr-et'],
    };
    updateMaxBRRequest(props, newList);
  };

  const fetchLicenses = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      await new Promise((resolve, reject) => {
        const custOrgLicenses = getLicenses();

        const deviceLicenses = [
          ...custOrgLicenses.filter(
            (license) =>
              license?.deviceId === deviceId &&
              license?.licenseStatus !== LicenseStatuses.EXPIRED,
          ),
        ];
        const availableLicenses = [...custOrgLicenses];
        if (deviceLicenses.length > 1) {
          setDeviceLicenses([
            ...deviceLicenses.filter(
              (deviceLicense) =>
                deviceLicense?.offeringType !== OfferingTypeEnum.ONBOARDING,
            ),
          ]);
        }
        if (deviceLicenses.length > 0) {
          setAvailableLicenses(true);
        } else {
          setAvailableLicenses(false);
        }
        resolve();
      });
    } else {
      throw new Error(
        'ERROR: there was problem retrieving the list of licenses for the organization',
      );
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, []);

  useEffect(() => {
    // Fetch settings from localStorage
    const wdrSettings = JSON.parse(localStorage.getItem('WDRSettings'));
    const irSettings = JSON.parse(localStorage.getItem('IRSettings'));

    setIsWdrVisible(wdrSettings !== false);
    setIsIrVisible(irSettings !== false);
  }, []);

  return (
    <div
      className={`text-start device-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          deviceResolution: !availableLicenses
            ? ''
            : deviceInfos?.properties?.['mp-resolution']
              ? deviceInfos?.properties?.['mp-resolution']
              : '',
          flip:
            deviceInfos?.properties?.['flip-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          mirror:
            deviceInfos?.properties?.['mirror-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          wdrLevel:
            deviceInfos?.properties?.['wdr-level']?.toLowerCase() ===
            constants.MEDIUM_VALUE_LABEL.toLowerCase()
              ? 2
              : deviceInfos?.properties?.['wdr-level']?.toLowerCase() ===
                  constants.MAX_VALUE_LABEL.toLowerCase()
                ? 3
                : 1,
          deviceDegrees: deviceInfos?.properties?.['rotation-angle']
            ? deviceInfos?.properties?.['rotation-angle']
            : '',
          wdrEnable:
            deviceInfos?.properties?.['wdr-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          wisestream:
            deviceInfos?.properties?.['wisestream-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicgov:
            deviceInfos?.properties?.['dynamic-gov-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicgovlength: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['dynamic-gov-length'],
            false,
          ),
          dynamicfps:
            deviceInfos?.properties?.['dynamic-fps-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          minimumfps: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['minimum-dynamic-fps'],
            false,
          ),
          brightness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['brightness-level'],
            false,
          ),
          bitRate: deviceInfos?.properties?.['bit-rate'],
          frameRate: deviceInfos?.properties?.['frame-rate'],

          sharpness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['sharpness-level'],
            false,
          ),
          contrast: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['contrast-level'],
            false,
          ),
          autoirmode:
            deviceInfos?.properties?.['ir-mode-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
          // TODO : Handle form submit use this later
          setSubmitting(true);
          setVariant('danger');
          setUserMsg('');
          setSubmitting(false);
          saveDeviceSettingsCallback(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <div>
              {curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS && (
                <div className="page-offline-message">
                  <div className="left-section">
                    <HiOutlineExclamationCircle
                      size={20}
                      stroke={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_24')}
                    />
                  </div>
                  <div className="right-section">
                    <div className="title-heading">
                      {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING}
                    </div>
                    <div className="sub-title-heading">
                      {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE}
                    </div>
                  </div>
                </div>
              )}

              <Row>
                <Col className="video-qty-top vdo-text-algn">
                  <div className="tab-title">
                    <label>{constants.VIDEO_QUALITY}</label>
                  </div>
                  {getCustomerOrgPolicies()?.view_metrics && (
                    <div
                      className="view-reports-text mt30"
                      onClick={() => {
                        Utils.vmsLogger().log('orgInfo', orgInfo);
                        navigate(
                          `/reports.html?deviceId=${deviceId}&deviceType=${'1'}&parentId=${
                            deviceInfos.parentId
                          }`,
                        );
                      }}
                    >
                      {constants.VIEW_REPORTS}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            {/* </div> */}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'mp-resolution',
              capVideoProfile,
            ) && (
              <Row>
                <Col className="display-g">
                  <div className="drop-holder">
                    <div>
                      <SelectField
                        name="deviceResolution"
                        options={deviceResolution}
                        label=""
                        prefixString={`${constants.RESOLUTION} : `}
                        defaultOptionLabel={
                          constants.DEVICES_TAB_VIDEO_SETTINGS_RESOLUTION_PLACEHOLDER
                        }
                        onChange={(evebr) => {
                          let selectedVal = evebr?.target?.value;
                          setFieldValue('deviceResolution', selectedVal);
                          const property = {
                            'mp-resolution': selectedVal.toString(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[2],
                            publishPayload,
                          );
                        }}
                        placeholderOptionClass="d-none"
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings || !availableLicenses}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'frame-rate',
              capVideoProfile,
            ) && (
              <Row className="recording-video-codec ml0">
                <Col className="p0-l">
                  <div>
                    {Utils.getPropertyShowStatus(
                      curDeviceStatus,
                      'frame-rate',
                      capVideoProfile,
                    ) && (
                      <Row className="settings-block">
                        <Col className="p0 range-pad">
                          <InputSlider
                            classes={'p-5-all'}
                            label={constants.FRAME_RATE}
                            maxValue={
                              deviceFPS
                                ? deviceFPS.data.maxValue
                                : Utils.getPropertyValueIfExist(
                                    capVideoProfile?.['frame-rate']?.max,
                                    true,
                                  )
                            }
                            minValue={Utils.getPropertyValueIfExist(
                              capVideoProfile?.['frame-rate']?.min,
                              false,
                            )}
                            fieldName="FrameRate"
                            currentValue={parseInt(values.frameRate)}
                            changeHandler={(value) => {
                              setFieldValue('frameRate', value);
                              const property = {
                                'frame-rate': value.toString(),
                              };
                              updateStore(property);
                              publishDeviceSetting(
                                property,
                                resourceList[2],
                                publishPayload,
                              );
                            }}
                            disabledClass={
                              !canChangeSettings ? 'cursor-auto' : ''
                            }
                            disabled={!canChangeSettings || !availableLicenses}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'frame-rate',
              capVideoProfile,
            ) && (
              <Row className="recording-video-codec ml0">
                <Col>
                  {Utils.getPropertyShowStatus(
                    curDeviceStatus,
                    'bit-rate',
                    capVideoProfile,
                  ) && (
                    <div className="drop-holder">
                      {/* <div className="title-vdo">
                        {constants.RECORDING_BITRATE}
                      </div>
                      <div className="title-vdo-2">
                        {constants.DEFAULT_BITRATE}
                      </div> */}
                      <div>
                        <Row className="settings-block">
                          <Col className="p0 range-pad">
                            <InputSlider
                              classes={'p-5-all-2'}
                              unitType={true}
                              label={constants.RECORDING_BITRATE}
                              maxValue={
                                deviceBitrate
                                  ? deviceBitrate.data.maxValue
                                  : Utils.getPropertyValueIfExist(
                                      capVideoProfile?.['bit-rate']?.max,
                                      true,
                                    )
                              }
                              minValue={Utils.getPropertyValueIfExist(
                                capVideoProfile?.['bit-rate']?.min,
                                false,
                              )}
                              fieldName="StreamingBitRate"
                              currentValue={values.bitRate}
                              changeHandler={(value) => {
                                setFieldValue('bitRate', value);
                                const property = {
                                  'bit-rate': value.toString(),
                                };
                                updateStore(property);
                                publishDeviceSetting(
                                  property,
                                  resourceList[2],
                                  publishPayload,
                                );
                              }}
                              disabledClass={
                                !canChangeSettings ? 'cursor-auto' : ''
                              }
                              disabled={
                                !canChangeSettings || !availableLicenses
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                  <div className="bitrate-hint">{constants.BITRATE_HINT}</div>
                  <Row className="settings-block">
                    <Col>
                      {allBrs?.map((br, index) => (
                        <div className="toggle-field-wrap mb-2">
                          <ToggleInput
                            curDeviceStatus={curDeviceStatus}
                            label={`${br['mbr-kbps']} kbps`}
                            name="kbps"
                            value={br['mbr-enable']}
                            classes={'kbps p-0'}
                            changeHandler={() => {
                              const status = {
                                'mbr-enable': !br['mbr-enable'],
                              };
                              setBitrateToggleStatus(status);
                              preUpdateKBPSRequest(status, br, index);
                            }}
                            labelClass={'kbps'}
                            disabledClass={
                              !canChangeSettings ? 'cursor-auto' : ''
                            }
                            disabled={!canChangeSettings || !availableLicenses}
                          />
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                              <div className="d-flex">
                                <div className="label-value">
                                  {getSelectedDays(br)}
                                </div>
                              </div>
                              <div>
                                {timeConversion(br['mbr-st'])} -{' '}
                                {timeConversion(br['mbr-et'])} {timezone}
                              </div>
                            </div>
                            <div>
                              <MdOutlineEdit
                                size={20}
                                onClick={() => {
                                  if (
                                    !canChangeSettings ||
                                    !availableLicenses
                                  ) {
                                    return;
                                  }
                                  onEditBr(br);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Col>
                  </Row>
                  {allBrs.length < 3 ? (
                    <Row className="settings-block ">
                      <Col>
                        <div
                          className={`setting-info-network ${
                            !canChangeSettings ? ' editable-off-offline' : ''
                          }`}
                          onClick={() => {
                            if (!availableLicenses) {
                              return;
                            }
                            setNetworkContinueModal(true);
                          }}
                        >
                          <div className="setting-plus-icon drag-title">
                            <img src={Plusred} alt="plusicon icon" />
                          </div>
                          {constants.SET_A_SCHEDULE_FOR_RECORDING_BITRATE}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'encoding-type',
              capVideoProfile,
            ) &&
              capVideoProfile?.['encoding-type']?.length > 0 && (
                <div className="recording-video-codec">
                  <div className="label-recording">
                    {constants.DEVICE_VIDEO_CODEC}
                  </div>
                  {recordCodecList.length > 0 &&
                    recordCodecList.map((record) => {
                      return (
                        Utils.getCodecAvailability(
                          record.label,
                          capVideoProfile?.['encoding-type'],
                        ) && (
                          <div
                            key={record.value}
                            className={`${
                              curDeviceStatus !==
                                constants.DEVICES_RETURN_ONLINE_STATUS &&
                              record.value
                                ? 'codec-selection-offline'
                                : 'codec-selection'
                            } ${
                              record.value !== 1 ? ' selection-disable' : ''
                            }`}
                          >
                            <div className="label-codec">{record.label}</div>
                            <Field
                              type="radio"
                              name="codecId"
                              value={record.value}
                              checked={defaultSelected === record.value}
                              onClick={(event) => {
                                setDefaultSelected(1);
                              }}
                            />
                          </div>
                        )
                      );
                    })}
                </div>
              )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'flip-enable',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_FLIP_LABEL}
                    name="flip"
                    value={values.flip}
                    changeHandler={() => {
                      const property = getCheckPropertyStatus(
                        !values.flip,
                        'flip-enable',
                      );
                      setPendingValue(!values.flip);
                      setFieldName('flip');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'mirror-enable',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_MIRROR_LABEL}
                    name="mirror"
                    value={values.mirror}
                    changeHandler={() => {
                      const property = getCheckPropertyStatus(
                        !values.mirror,
                        'mirror-enable',
                      );
                      setPendingValue(!values.mirror);
                      setFieldName('mirror');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'rotation-angle',
              capFlipRotate,
            ) && (
              <Row className="settings-block">
                <Col>
                  {/* TODO: The options label is going to change with the API response data once API is available */}
                  <SelectField
                    name="deviceDegrees"
                    options={deviceDegreeRotation}
                    label=""
                    defaultOptionLabel={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DEGREES_PLACEHOLDER
                    }
                    value={values.deviceDegrees}
                    onChange={(evebr) => {
                      let selectedVal = evebr?.target?.value;
                      const property = { 'rotation-angle': selectedVal };
                      setPendingValue(selectedVal);
                      setFieldName('deviceDegrees');
                      setPayloadProperty(property);
                      setShowConfirmationModal(true);
                    }}
                    placeholderOptionClass="d-none"
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {isWdrVisible &&
              Utils.getPropertyShowStatus(
                curDeviceStatus,
                'wdr-enable',
                capWdrEnable,
              ) && (
                <Row
                  className={`settings-block ${
                    values.wdrEnable ? 'grouped-settings-block' : ''
                  }`}
                >
                  <Col md={12}>
                    <ToggleInput
                      curDeviceStatus={curDeviceStatus}
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_WDR_LEVEL_LABEL
                      }
                      classes={values.wdrEnable ? 'mixed-lower' : ''}
                      name="wdrEnable"
                      value={values.wdrEnable}
                      changeHandler={() => {
                        setFieldValue('wdrEnable', !values.wdrEnable);
                        const status = getCheckPropertyStatus(
                          !values.wdrEnable,
                          'wdr-enable',
                        );
                        updateStore(status);
                        publishDeviceSetting(
                          status,
                          resourceList[1],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                  <Col md={12}>
                    {values.wdrEnable && (
                      <InputSlider
                        classes={'mixed'}
                        label={''}
                        thumbLabelHide={true}
                        fieldName="wdrLevel"
                        maxValue={3}
                        currentValue={values.wdrLevel}
                        changeHandler={(value) => {
                          setFieldValue('wdrLevel', value);
                          const property = {
                            'wdr-level':
                              value === 1
                                ? constants.MIN_VALUE_LABEL.toLowerCase()
                                : value === 2
                                  ? constants.MEDIUM_VALUE_LABEL.toLowerCase()
                                  : constants.MAX_VALUE_LABEL.toLowerCase(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[1],
                            publishPayload,
                          );
                        }}
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    )}
                  </Col>
                </Row>
              )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'wisestream-enable',
              capWisestream,
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_WISE_STREAM_LABEL
                    }
                    name="wisestream"
                    value={values.wisestream}
                    changeHandler={() => {
                      setFieldValue('wisestream', !values.wisestream);
                      const status = getCheckPropertyStatus(
                        !values.wisestream,
                        'wisestream-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[3],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-fps-enable',
              capVideoProfile,
            ) && (
              <Row
                className={`settings-block ${
                  values.dynamicfps ? 'grouped-settings-block' : ''
                }`}
              >
                <Col md={12}>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_FPS_LABEL
                    }
                    name="dynamicfps"
                    value={values.dynamicfps}
                    changeHandler={() => {
                      setFpsReuest(true);
                      setFieldValue('dynamicfps', !values.dynamicfps);
                      const status = getCheckPropertyStatus(
                        !values.dynamicfps,
                        'dynamic-fps-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings || fpsReuest}
                  />
                </Col>
                {values.dynamicfps && (
                  <Col md={12}>
                    <InputSlider
                      label={''}
                      fieldName="minimumfps"
                      maxValue={
                        selectedFrameRate !== null
                          ? parseInt(selectedFrameRate)
                          : parseInt(values.frameRate)
                      }
                      minValue={getDynamicFPSMinValue(values)}
                      currentValue={
                        selectedFrameRate !== null
                          ? Math.floor(selectedFrameRate / 2)
                          : values.minimumfps
                      }
                      changeHandler={(value) => {
                        setSelectedFrameRate(null);
                        setFpsReuest(true);
                        setFieldValue('minimumfps', value);
                        const property = {
                          'minimum-dynamic-fps': value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          resourceList[2],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings || fpsReuest}
                    />
                  </Col>
                )}
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'brightness-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_BRIGHTNESS_LABEL
                    }
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.min,
                      false,
                    )}
                    fieldName="brightness"
                    currentValue={values.brightness}
                    changeHandler={(value) => {
                      setFieldValue('brightness', value);
                      const property = {
                        'brightness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'sharpness-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_SHARPNESS_LABEL}
                    fieldName="sharpness"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.min,
                      false,
                    )}
                    currentValue={values.sharpness}
                    changeHandler={(value) => {
                      setFieldValue('sharpness', value);
                      const property = {
                        'sharpness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'contrast-level',
              capEnhacement,
            ) && (
              <Row className="settings-block">
                <Col>
                  <InputSlider
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_CONTRAST_LABEL}
                    fieldName="contrast"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.max,
                      true,
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.min,
                      false,
                    )}
                    currentValue={values.contrast}
                    changeHandler={(value) => {
                      setFieldValue('contrast', value);
                      const property = {
                        'contrast-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
              </Row>
            )}

            {isIrVisible &&
              Utils.getPropertyShowStatus(
                curDeviceStatus,
                'ir-mode-enable',
                capIrMode,
              ) && (
                <Row className="settings-block">
                  <Col>
                    <ToggleInput
                      curDeviceStatus={curDeviceStatus}
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_AUTO_IR_MODE_LABEL
                      }
                      name="autoirmode"
                      value={values.autoirmode}
                      changeHandler={() => {
                        setFieldValue('autoirmode', !values.autoirmode);
                        const status = getCheckPropertyStatus(
                          !values.autoirmode,
                          'ir-mode-enable',
                        );
                        updateStore(status);
                        publishDeviceSetting(
                          status,
                          resourceList[4],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                </Row>
              )}

            {capVideoProfile?.['dynamic-gov-length'] &&
            Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-gov-length',
              capVideoProfile,
            ) ? (
              <Row
                className={`settings-block ${
                  values.dynamicgov ? 'grouped-settings-block' : ''
                }`}
              >
                <Col md={12}>
                  <ToggleInput
                    curDeviceStatus={curDeviceStatus}
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL
                    }
                    name="dynamicgov"
                    value={values.dynamicgov}
                    changeHandler={() => {
                      setFieldValue('dynamicgov', !values.dynamicgov);
                      const status = getCheckPropertyStatus(
                        !values.dynamicgov,
                        'dynamic-gov-enable',
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload,
                      );
                    }}
                    disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                    disabled={!canChangeSettings}
                  />
                </Col>
                {values.dynamicgov && (
                  <Col md={12}>
                    <InputSlider
                      fieldName="dynamicgovlength"
                      maxValue={capVideoProfile?.['dynamic-gov-length']?.max}
                      minValue={capVideoProfile?.['dynamic-gov-length']?.min}
                      currentValue={values.dynamicgovlength}
                      changeHandler={(value) => {
                        setFieldValue('dynamicgovlength', value.toString());
                        const property = {
                          'dynamic-gov-length': value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          resourceList[2],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              Utils.getPropertyShowStatus(
                curDeviceStatus,
                'dynamic-gov-enable',
                capVideoProfile,
              ) && (
                <Row className="settings-block">
                  <Col>
                    <ToggleInput
                      curDeviceStatus={curDeviceStatus}
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL
                      }
                      name="dynamicgov"
                      value={values.dynamicgov}
                      changeHandler={() => {
                        setFieldValue('dynamicgov', !values.dynamicgov);
                        const status = getCheckPropertyStatus(
                          !values.dynamicgov,
                          'dynamic-gov-enable',
                        );
                        updateStore(status);
                        publishDeviceSetting(
                          status,
                          resourceList[2],
                          publishPayload,
                        );
                      }}
                      disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                      disabled={!canChangeSettings}
                    />
                  </Col>
                </Row>
              )
            )}

            <SiteModal
              modalTitle={constants.DEVICES_TAB_CUSTOM_ANALYTICS_MODAL_TITLE}
              showModal={showConfirmationModal}
              hideModal={() => {
                setShowConfirmationModal(false);
              }}
              classes="device-settings-restart-required"
              analyticSetting={true}
              isCenteredTitle={true}
            >
              <TextBlock
                className="mb-4"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--greyscale_40')}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_1
                }
              </TextBlock>
              {(fieldName === 'flip' || fieldName === 'deviceDegrees') && (
                <TextBlock
                  className="mb-4"
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_40')}
                >
                  {
                    constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_2
                  }
                </TextBlock>
              )}
              <TextBlock
                className="mb-4"
                fontWeight="400"
                fontSize="1rem"
                lineHeight="24px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--greyscale_40')}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_CUSTOM_ANALYTICS_MODAL_DESC_3
                }
              </TextBlock>
              <PrimaryButton
                className="btn btn-primary mb-4"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={() => {
                  handleChange(setFieldValue);
                  setShowConfirmationModal(false);
                }}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_MODAL_CONTINUE_BUTTON_TITLE
                }
              </PrimaryButton>
              <PrimaryButton
                className="btn btn-primary-outline"
                type="button"
                width="100%"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverBorderColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_white')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                backgroundColor="transparent"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                {
                  constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
                }
              </PrimaryButton>
            </SiteModal>
          </Form>
        )}
      </Formik>
      {/* CONFIRM POPUP */}
      <SiteModal
        modalTitle={constants.DEVICES_TAB_VIDEOSETTING_RESTART_REQUIRED_TITLE}
        showModal={ConfirmRestartRequiredModal}
        hideModal={() => {
          // setIsEditMode(null);
          setConfirmRestartRequiredModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56',
          )}
        >
          <div className="contentTitle">
            {constants.DEVICE_TAB_VIDEO_RESTART_TEXT}
          </div>
        </TextBlock>
        <PrimaryButton
          className="btn mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_primary')}
          height="56px"
          fontSize="1rem"
          lineHeight="24px"
          onClick={() => {
            if (modalType === 2) {
              addMaxStrRequest();
            }
            if (modalType === 3) {
              preUpdateRequest();
            }
          }}
        >
          {constants.DEVICES_CONFIRM_TEXT}
        </PrimaryButton>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setConfirmRestartRequiredModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>

      <SiteModal
        modalTitle={constants.SET_SCHEDULE_FOR_RECORDING}
        showModal={NetworkContinueModal}
        hideModal={() => {
          setNetworkContinueModal(false);
          setIsEditMode(null);
          resetProps();
        }}
        classes="device-settings-restart-required streamingSetting streamingSetting-title"
      >
        <div className="alert-recording-close">
          {showMsg ? (
            <Alert
              variant={variant}
              onClose={() => setShowMsg(false)}
              dismissible
              className="alert-recording-bitrate"
            >
              <Row>
                <Col md={1} className="alert-icon-recording-bitrate">
                  <HiOutlineInformationCircle
                    size={15}
                    stroke={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--error_24')}
                  />
                </Col>
                <Col>
                  <p>{constants.DEVICES_TIME_OVERLAPPING_MESSAGE}</p>
                </Col>
              </Row>
            </Alert>
          ) : (
            <></>
          )}

          <div className="settings-block settings-block-range">
            <div class="max-recording-bitrate-label">
              {constants.MAXIMUM_RECORDING_BITRATE}
            </div>
            <div className="max-recording-bitrate-range">
              <div className="range-field-container">
                <InputSlider
                  classes={'p-5-all-2'}
                  unitType={true}
                  maxValue={max}
                  minValue={min}
                  isErrorMessage={false}
                  fieldName="StreamingBitRate"
                  currentValue={parseInt(kbpsValue)}
                  changeHandler={(value) => {
                    setkbpsValue(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="week-header mb-0 p-0">
            <div className="max-recording-bitrate-label">
              {constants.DEVICES_TAB_NETWORK_MAX_STREAMING_BITRATE_MODAL_DAY}
            </div>
          </div>
          <div
            className={`select-day-container ${showMsg ? 'select-day-container-error' : ''}`}
          >
            {daysList?.map((days) => {
              return (
                <div
                  onClick={() => selectedDay(days)}
                  key={days.id}
                  className={`day-blocks ${days.isSelected ? 'selected' : ''}`}
                >
                  <div className="label-value">{days.dayPrefix}</div>
                </div>
              );
            })}
          </div>
          <div className="sm-lable selectd-day"> {commaSeparatedDays()}</div>
          <div className="week-header choose-time p-0 mb-2">
            <div className="choose-day site-text-block d-block mb-0">
              {constants.TIME_RANGE}
              <br />
              <p className="sm-lable mb-0">
                {constants.CHOOSE_TIME_RANGE_SUB_CONTENT} {timezone}
              </p>
            </div>
          </div>
          <div className="time-container">
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(startTimeMoment, true)}
            </div>
            <div className="seprator">-</div>
            <div className={`timer-start-end ${showMsg ? 'error' : ''}`}>
              {renderTimePicker(endTimeMoment, false)}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-4">
          {isEditMode !== null ? (
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="120px"
              height="44px"
              fontSize="14PX"
              lineHeight="24px"
              borderWidth="1.5px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--slider-red')}
              onClick={() => deleteMaxStrRequest()}
            >
              {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE}
            </PrimaryButton>
          ) : null}
          <PrimaryButton
            className="btn ms-2"
            width="120px"
            type="button"
            borderWidth="1.5px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_primary')}
            height="44PX"
            fontSize="14PX"
            lineHeight="24px"
            disabled={!selectedIds.length || isOverlaping}
            onClick={() =>
              isEditMode !== null ? getUpdateConfirmation() : getConfirmation()
            }
          >
            {isEditMode !== null
              ? constants.DEVICES_TAB_NETWORK_MAXIMUM_UPDATE
              : constants.DEVICES_TAB_NETWORK_MAXIMUM_ADD}
          </PrimaryButton>
        </div>
      </SiteModal>
      <SiteModal
        modalTitle={constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_TITLE}
        showModal={showDeviceRestartModal}
        hideModal={() => {
          setShowDeviceRestartModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_40',
          )}
        >
          {constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONTENT}
        </TextBlock>
        <PrimaryButton
          className="btn btn-primary mb-4"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONFIRM_BUTTON_TITLE
          }
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40',
          )}
          hoverColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setShowDeviceRestartModal(false);
          }}
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
          }
        </PrimaryButton>
      </SiteModal>
    </div>
  );
};

export default DeviceSettingsBlock;
