import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';

import {
  ListBlock,
  PrimaryButton,
  SiteModal,
} from '../../../../components/common';
import {
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  Utils,
  AppDefaults,
} from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import AddLicenseForm from './AddLicenseForm';
import DetachLicenseForm from './DetachLicenseForm';

import './LicensesBlock.scss';
import { setOfferrecived } from '../../../../store/reducers/StreamingReducer';
import { useDispatch } from 'react-redux';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { PiWarning } from 'react-icons/pi';

const DATE_FORMAT = 'DD MMM YYYY';

const LicensesBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  deviceTimeZone,
  showRemoveModal,
  hideRemoveModalCallback,
  deviceInfos,
  hasManagePermission,
  canAttachLicense,
}) => {
  const { licenses, getLicenses, setLicenses, setIslicensesAttachStatus } =
    useLicensesStore();
  const navigate = useNavigate();
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [licenseName, setLicenseName] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [showDetachLicenseModal, setShowDetachLicenseModal] =
    useState(showRemoveModal);
  const dispatch = useDispatch();

  let lastExpirationDate;
  useEffect(() => {
    try {
      if (!Array.isArray(licenses) || licenses.length < 1) {
        fetchLicenses();
      } else {
        if (licenses.length > 0) {
          const updatedLicenses = getLicenses();
          const currentTime = new Date().getTime();
          const filterList = updatedLicenses?.filter(
            (license) => license?.deviceId === deviceId,
          );
          const updatedFilterList = filterList?.filter(
            (license) =>
              !(
                license?.offeringType === OfferingTypeEnum.ONBOARDING &&
                license?.licenseStatus === LicenseStatuses.EXPIRED
              ) &&
              !(
                license?.offeringType === OfferingTypeEnum.EVAL &&
                license?.licenseStatus === LicenseStatuses.EXPIRED
              ),
          );
          const finalList = [...updatedFilterList].sort(
            (a, b) => a.expiryDate - b.expiryDate,
          );
          const deviceLicenses = [...finalList];
          if (deviceLicenses?.length > 0) {
            setIslicensesAttachStatus(1);
          } else {
            dispatch(setOfferrecived(false));
            setIslicensesAttachStatus(2);
          }
          setDeviceLicenses([...deviceLicenses]);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  }, [JSON.stringify(licenses)]);

  const showAttachLicenseButton = () => {
    return canAttachLicense ? (
      <PrimaryButton
        type="button"
        width="100%"
        className="device-btn-space attach-new-license-btn"
        onClick={() => setShowAddLicenseModal(true)}
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_LICENSE_LABEL}
      </PrimaryButton>
    ) : (
      <Button
        variant="primary"
        width="100%"
        className="attach-license-btn attach-new-license-btn disabled"
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_LICENSE_LABEL}
      </Button>
    );
  };

  const fetchLicenses = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      await new Promise((resolve, reject) => {
        const custOrgLicenses = getLicenses();
        const currentTime = new Date().getTime();

        const filteredCustOrgLicenses = custOrgLicenses?.filter(
          (license) => license?.deviceId === deviceId,
        );
        const filterList = [...filteredCustOrgLicenses].sort(
          (a, b) => a.expiryDate - b.expiryDate,
        );
        const finalList = filterList?.filter(
          (license) =>
            !(
              license?.offeringType === OfferingTypeEnum.ONBOARDING &&
              license?.licenseStatus === LicenseStatuses.EXPIRED
            ) &&
            !(
              license?.offeringType === OfferingTypeEnum.EVAL &&
              license?.licenseStatus === LicenseStatuses.EXPIRED
            ),
        );
        const deviceLicenses = [...finalList];
        setAvailableLicenses([...custOrgLicenses]);
        setDeviceLicenses([...deviceLicenses]);
        resolve();
      });
    } else {
      throw new Error(
        'ERROR: there was problem retrieving the list of licenses for the organization',
      );
    }
  };

  const detachLicenseHandler = (detachLicenseName, detachLicenseKey) => {
    if (!detachLicenseName || !detachLicenseKey) return;

    setLicenseName(detachLicenseName);
    setLicenseKey(detachLicenseKey);
    setShowDetachLicenseModal(true);
  };

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    const adjustedDate = moment(
      deviceLicense.expiryDate -
        deviceLicense.gracePeriod * AppDefaults.DAY_IN_MILLISECONDS,
    );
    return adjustedDate.format(DATE_FORMAT);
  };

  const getRemainingDays = (license) => {
    const expiryDate = license.expiryDate ? parseInt(license.expiryDate) : 0;
    let date = new Date(expiryDate);
    date.setDate(date.getDate() - (license.gracePeriod || 0));
    const currentDate = new Date();
    const timeDifference = date - currentDate;
    const diffInDays = Math.floor(
      timeDifference / AppDefaults.DAY_IN_MILLISECONDS,
    );
    const result = diffInDays <= 0 || isNaN(diffInDays) ? 0 : diffInDays;
    return result;
  };

  const showExpiringInLabel = (deviceLicense) => {
    return (
      deviceLicense?.licenseStatus !== LicenseStatuses.EXPIRED &&
      (getRemainingDays(deviceLicense) === 0 ||
        getRemainingDays(deviceLicense) <=
          AppDefaults?.LICENSE_EXPIRING_SOON_LIMIT)
    );
  };

  const checkEvalOrOnboardingLicense = (deviceLicense) => {
    return (
      deviceLicense?.offeringType !== OfferingTypeEnum.ONBOARDING &&
      deviceLicense?.offeringType !== OfferingTypeEnum.EVAL
    );
  };

  const getLicenseStatusValues = (deviceLicense) => {
    return (
      <Row
        className={
          checkEvalOrOnboardingLicense(deviceLicense)
            ? 'license-prop-value'
            : 'license-prop-value-eval-onabording'
        }
      >
        <div>
          {(getRemainingDays(deviceLicense) === 0 ||
            deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED) &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineExclamationCircle className="license-expiring-soon-icon-style error-icon-style" />
            )}
          {getRemainingDays(deviceLicense) > 0 &&
            getRemainingDays(deviceLicense) <=
              AppDefaults.LICENSE_EXPIRING_SOON_LIMIT &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineExclamationCircle className="license-expiring-soon-icon-style expiring-soon-icon-style" />
            )}
          {getRemainingDays(deviceLicense) >
            AppDefaults.LICENSE_EXPIRING_SOON_LIMIT &&
            checkEvalOrOnboardingLicense(deviceLicense) && (
              <HiOutlineCheckCircle className="license-expiring-soon-icon-style success-icon-style" />
            )}
          {deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED ? (
            constants.DEVICES_EXPIRED_LABEL_DEVICE_STATUS
          ) : (
            <>
              {showExpiringInLabel(deviceLicense) ? (
                <>
                  {constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_IN_LABEL}{' '}
                  {getRemainingDays(deviceLicense)}{' '}
                  {constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_LABEL}
                </>
              ) : (
                <>
                  {getRemainingDays(deviceLicense)}{' '}
                  {
                    constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRING_DAYS_REMAINING_LABEL
                  }
                </>
              )}
            </>
          )}
        </div>
      </Row>
    );
  };

  const getLicenseExpirationDate = (deviceLicense) => {
    return (
      <div>
        {/* icon area starts */}
        {getRemainingDays(deviceLicense) === 0 ? (
          deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED ? (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          ) : checkEvalOrOnboardingLicense(deviceLicense) ? (
            <PiWarning className="license-expiring-soon-icon-style warning-icon-style" />
          ) : (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          )
        ) : (
          getRemainingDays(deviceLicense) > 0 && (
            <HiOutlineCalendar
              size={20}
              className="license-expiring-soon-icon-style"
            />
          )
        )}
        {/* Icon area ends */}
        {/* Label area starts */}
        <span
          className={
            getRemainingDays(deviceLicense) === 0
              ? deviceLicense?.licenseStatus === LicenseStatuses.EXPIRED
                ? 'license-prop-date-value'
                : 'license-warning-prop-date-value'
              : 'license-prop-date-value'
          }
        >
          {calculateAdjustedExpiryDate(deviceLicense)}
        </span>
        {/* Label area ends */}
      </div>
    );
  };

  return (
    <>
      <ListBlock
        className="list-block license-block card-header-block-area"
        contentWrapperClass="text-start"
        label={constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
      >
        <Row>
          <Col>
            {/* to do : change later when work on locations part <Mapimage /> */}
            <ul className="license-features">
              {Array.isArray(deviceLicenses) && deviceLicenses.length > 0 ? (
                deviceLicenses.map((deviceLicense, dlIndex) => {
                  const today = moment().unix() * 1000;

                  if (!lastExpirationDate) {
                    if (
                      deviceLicense?.offeringType ===
                      OfferingTypeEnum.ONBOARDING
                    ) {
                      lastExpirationDate = today;
                    } else {
                      lastExpirationDate = deviceLicense.expiryDate;
                    }
                  } else if (lastExpirationDate < deviceLicense.expiryDate) {
                    lastExpirationDate = deviceLicense.expiryDate;
                  }

                  return (
                    <li key={`devLicense${dlIndex}`}>
                      <Container>
                        <Row className="license-block-row-style">
                          <div className="Col col-md-8 col-sm-8 col-8">
                            <Row className="license-prop-title">
                              {constants.DEVICES_TAB_LICENSES_LICENSE_TYPE}
                            </Row>
                            <Row className="license-prop-value">
                              {deviceLicense.productDisplayName}
                            </Row>
                          </div>
                          <div className="Col col-md-4 col-sm-4 col-4">
                            <Row className="license-prop-title">
                              {constants.DEVICES_TAB_LICENSES_LICENSE_STATUS}
                            </Row>
                            {getLicenseStatusValues(deviceLicense)}
                          </div>
                        </Row>
                        <Row>
                          <div className="Col col-md-8 col-sm-8 col-8">
                            <Row className="license-prop-title">
                              {
                                constants.DEVICES_TAB_LICENSES_LICENSE_START_DATE
                              }
                            </Row>
                            <Row className="license-prop-calender-value">
                              <div>
                                <HiOutlineCalendar
                                  size={20}
                                  className="license-expiring-soon-icon-style"
                                />
                                <span className="license-prop-date-value">
                                  {moment(deviceLicense.activeDate).format(
                                    DATE_FORMAT,
                                  )}
                                </span>
                              </div>
                            </Row>
                          </div>
                          <div className="Col col-md-4 col-sm-4 col-4">
                            <Row className="license-prop-title">
                              {
                                constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE
                              }
                            </Row>
                            <Row className="license-prop-calender-value">
                              {getLicenseExpirationDate(deviceLicense)}
                            </Row>
                          </div>
                        </Row>
                        <Row>
                          <div className="Col col-md-8 col-sm-8 col-8">
                            <div
                              className={`license-action license-action-style${
                                deviceLicense?.offeringType ===
                                  OfferingTypeEnum.ONBOARDING ||
                                !hasManagePermission
                                  ? ' hidden'
                                  : ''
                              }`}
                              onClick={() =>
                                detachLicenseHandler(
                                  deviceLicense.productDisplayName,
                                  deviceLicense.licenseKey,
                                )
                              }
                            >
                              {constants.DEVICES_TAB_LICENSES_DETACH_LICENSE}
                            </div>
                          </div>
                        </Row>
                      </Container>
                    </li>
                  );
                })
              ) : Array.isArray(availableLicenses) &&
                availableLicenses.length < 1 ? (
                <li>
                  <Container>
                    <Row>
                      <Col>
                        <Row>
                          <div className="no-licenses-attached">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                          </div>
                        </Row>
                        <Row>
                          <div className="no-licenses-available">
                            {
                              constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE
                            }
                          </div>
                        </Row>
                        {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                          <Row>
                            <div className="purchase-button-container">
                              <PrimaryButton
                                width="200px"
                                onClick={() => {
                                  navigate(
                                    `/products/listing.html?orgId=${orgId}&orgName=${orgName}`,
                                  );
                                }}
                              >
                                {
                                  constants.DEVICES_TAB_LICENSES_PURCHASE_LICENSE
                                }
                              </PrimaryButton>
                            </div>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </li>
              ) : (
                <li>
                  <Row>
                    <Col>
                      <Row>
                        <div className="no-licenses-attached">
                          {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                        </div>
                      </Row>
                      <Row>
                        <div className="no-licenses-available-sub">
                          {constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
            {showAttachLicenseButton()}
          </Col>
        </Row>
      </ListBlock>
      {/* MODALS */}
      {/* Attach License Modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_MODAL_TITLE,
          '$deviceName',
          deviceName,
        )}
        showModal={showAddLicenseModal}
        hideModal={() => {
          setShowAddLicenseModal(false);
        }}
        classes="add-license-modal"
      >
        <AddLicenseForm
          deviceId={deviceId}
          deviceTimeZone={deviceTimeZone}
          orgId={orgId}
          expiryDate={lastExpirationDate}
          deviceLicenses={deviceLicenses}
          callBack={() => {
            fetchLicenses();
            setShowAddLicenseModal(false);
          }}
        />
      </SiteModal>
      {/* Detach License */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_DETACH_LICENSE_MODAL_TITLE,
          '$licenseName',
          licenseName,
        )}
        showModal={showDetachLicenseModal}
        hideModal={() => {
          setShowDetachLicenseModal(false);
          if (
            hideRemoveModalCallback &&
            hideRemoveModalCallback.constructor !== String &&
            hideRemoveModalCallback.constructor !== Number &&
            hideRemoveModalCallback.constructor !== Object &&
            hideRemoveModalCallback.constructor !== Array
          ) {
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }
        }}
        classes="detach-license-modal"
      >
        <DetachLicenseForm
          deviceId={deviceId}
          licenseKey={licenseKey}
          orgId={orgId}
          callBack={() => {
            fetchLicenses();
            setShowDetachLicenseModal(false);
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }}
        />
      </SiteModal>
    </>
  );
};

export default LicensesBlock;
