import { Section, SingleColumnLayout } from '../components';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';
import {
  DevicesFilter,
  DropdownList,
  MetricCard,
  SiteSpinner,
  SiteModal,
  DateTimePicker,
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';
import BaseChartConfig from '../shared/BaseChartConfig';
import { useNavigate } from 'react-router';
import '../CameraAvailability.scss';
import { Row } from 'react-bootstrap';
import CamAvailableListTable from './camAvailableListTable';
import { useMemo } from 'react';
import moment from 'moment';
import {
  getSelectedDeviceFromFillter,
  setSelectedDeviceFromFillter,
} from '../../../store/CustomerOrgDevicesStoreIDB';
import DatePicker from 'react-multi-date-picker';
import useEventsStore from '../../../store/EventsStore';

const pastTimeSeries = [
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
    itemValue: 1,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
    itemValue: 2,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
    itemValue: 7,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
    itemValue: 14,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
    itemValue: 30,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
    itemValue: 365,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
    itemValue: 730,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE,
    itemValue: 'custom',
  },
];

const frequencies = [
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
    itemValue: 'hourly',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
    itemValue: 'daily',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
    itemValue: 'weekly',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
    itemValue: 'monthly',
  },
];

const frequencyGetter = {
  hourly: Utils.getHourFromEpoch,
  daily: Utils.getDateFromEpoch,
  weekly: Utils.getWeekFromEpoch,
  monthly: Utils.getMonthFromEpoch,
};

const CameraAvailableDetails = ({
  custOrgData,
  devices,
  id,
  columnsData,
  selectedPastRange,
  selectedPastRangeLabel,
  selectedDuration,
  selectedPastDuration,
  parentFilter,
}) => {
  const calendarRef = useRef();
  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [filterDate, setFilterDate] = useState(parentFilter);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [timezone, setTimezone] = useState(null);
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);

  const [options, setOptions] = useState({
    ...BaseChartConfig,
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          let retVal =
            id === 1
              ? parseInt(value) === 0
                ? value
                : `${value.toFixed(2)}%`
              : `${value.toFixed(2)}%`;

          return retVal;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56',
      ),
    ],
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '0%',
    description:
      constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_HIGHLIGHT_DESCRIPTION,
  });

  const [averageCameraOnlineTime, setAverageCameraOnlineTime] = useState(98);
  const [pastRange, setPastRange] = useState(selectedPastRange);
  const [pastRangeLabel, setPassRangeLabel] = useState(selectedPastRangeLabel);
  const [duration, setDuration] = useState(selectedDuration);
  const [durationRange, setSelectedDuration] = useState(selectedPastDuration);

  // const {
  //   getCustomerOrgDevices,
  //   setSelectedDeviceFromFillter,
  //   getSelectedDeviceFromFillter,
  // } = useCustomerOrgDevices();
  const navigate = useNavigate();

  const custOrgDataRef = useRef(custOrgData);

  if (custOrgData?.orgId) {
    custOrgDataRef.current = custOrgData;
  }

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  useEffect(() => {
    if (showCustomRange) {
      calendarRef?.current?.openCalendar();
    }
  }, [showCustomRange]);

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };
  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const [showDateTimeModal, setShowDateTimeModal] = useState(false);

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        await setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId,
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          await setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      await setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, deviceData);
    }
  };
  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const dynamicWidth = series[0]?.data?.length * 45;
  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const chartContent = (
    <div className="scrollable-chart-container">
      <Chart
        options={options}
        series={series}
        type={id == 1 ? 'line' : 'bar'}
        height={200}
        width={chartWidth}
      />
    </div>
  );

  const topRightLinks = (
    <div className="filter-group">
      {/* Filter */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
        hasSecondaryDropdown={true}
        items={filterContent}
        show={show}
        setShow={(status) => {
          setShow(status);
        }}
      />
      {/* Past Range */}
      <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          if (selectedKey === 'custom') {
            setShowCustomRange(true);
            setShowDateTimeModal(true);
            calendarRef?.current?.openCalendar();
          } else {
            setPastRange(-Math.abs(selectedKey));
            setShowDateTimeModal(false);
          }
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
      {/* Duration */}
      <DropdownList
        defaultLabel={durationRange}
        items={frequencies}
        show={rangeDay}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);

          switch (selectedKey.toUpperCase()) {
            case 'HOURLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
              );
              break;

            case 'DAILY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
              );
              break;

            case 'WEEKLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
              );
              break;

            default:
            // Do not do anything
          }
        }}
      />
    </div>
  );

  const getDeviceIdsString = async (devicList) => {
    const deviceSelected = await getSelectedDeviceFromFillter();
    let deviceIdsString = '';
    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId,
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.parentId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const getDeviceLocationName = (device, isForDevice) => {
    const currentCustOrgData = custOrgDataRef.current;
    let deviceLocation = '--';
    const deviceData = currentCustOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId,
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = currentCustOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId,
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId,
          );
          if (areas) {
            deviceLocation = `${location?.locationName} - ${areas?.areaName}`;
          }
        }
      }
    }
    return deviceLocation;
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0) return;

      try {
        const currentCustOrgData = custOrgDataRef.current;
        if (!currentCustOrgData?.orgId) return;
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${currentCustOrgData?.orgId}/trend/device-connection-status`;
        const requestId = uuidv4();
        let date = new Date();
        date.setDate(date.getDate() + newPastRange);
        const deviceIdsList = await getDeviceIdsString(devicesList);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: currentCustOrgData?.orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: Utils.getDuration(newPastRange, newDuration),
            deviceIds: deviceIdsList,
            requestTime: Date.now(),
            requestId: requestId,
          },
          ...Utils.requestHeader(requestId),
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
        });

        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          averagePercentageOnline,
          totalPercentageOnline,
          totalDevicesOnlinePercentages,
          timeSeriesData,
          offlineTimeAverage,
          offlineTimeTotal,
          timestamp,
          totalDevicesOfflinePercentages;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;
          if (Array.isArray(respData) && respData?.length > 0) {
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['weekly'];
                break;

              case -730:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['monthly'];
                break;

              default:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter[newDuration];
            }
            const tableList = [];
            //timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp);

            if (id === 1) {
              totalDevicesOnlinePercentages = 0;
              let formattedTime;
              timeSeriesData.forEach((time) => {
                if (newDuration === 'hourly') {
                  // Format as HH:mm for hourly
                  formattedTime = moment(new Date(time.timestamp)).format(
                    'DD MMM YYYY hh:mm a',
                  );
                } else {
                  // Default formatting for other durations
                  formattedTime = moment(new Date(time.timestamp)).format(
                    'DD MMM YYYY',
                  );
                }
                averagePercentageOnline = 0;
                totalPercentageOnline = 0;
                timestamp = timestampGetter(time.timestamp);
                xAxis.push(timestamp);
                if (Array.isArray(time.devices) && time.devices.length > 1) {
                  time.devices.forEach((device) => {
                    totalPercentageOnline += device.percentageOnline;
                    const tableItem = {
                      monthYear: formattedTime,
                      deviceName: getDeviceLocationName(device, true),
                      locationArea: getDeviceLocationName(device, false),
                      percentageOnline:
                        device?.percentageOnline + `${id == 1 ? '%' : 'm'}`,
                    };
                    tableList.push(tableItem);
                  });

                  averagePercentageOnline =
                    totalPercentageOnline / time.devices.length;
                } else {
                  averagePercentageOnline = time.devices[0].percentageOnline;
                  const tableItem = {
                    monthYear: formattedTime,
                    deviceName: getDeviceLocationName(time?.devices[0], true),
                    locationArea: getDeviceLocationName(
                      time?.devices[0],
                      false,
                    ),
                    percentageOnline: time?.devices[0]?.percentageOnline + '%',
                  };
                  tableList.push(tableItem);
                }
                yValues.push(averagePercentageOnline);

                totalDevicesOnlinePercentages += averagePercentageOnline;
              });

              setTimeSeriesData(tableList);

              setAverageCameraOnlineTime(
                (totalDevicesOnlinePercentages / timeSeriesData.length).toFixed(
                  2,
                ),
              );

              setHighlight((highlight) => ({
                value: `${(
                  totalDevicesOnlinePercentages / timeSeriesData.length
                ).toFixed(2)}%`,
                description: highlight?.description,
              }));
            } else {
              timeSeriesData.forEach((time) => {
                offlineTimeAverage = 0;
                offlineTimeTotal = 0;
                timestamp = timestampGetter(time.timestamp);

                xAxis.push(timestamp);

                if (Array.isArray(time.devices) && time.devices.length > 0) {
                  time.devices.forEach((device) => {
                    offlineTimeTotal += device.percentageOffline;

                    const tableItem = {
                      monthYear: moment(new Date(time.timestamp)).format(
                        'DD MMM yyyy hh:mm ',
                      ),
                      deviceName: getDeviceLocationName(device, true),
                      locationArea: getDeviceLocationName(device, false),
                      percentageOnline: device?.percentageOffline + `%`,
                    };
                    tableList.push(tableItem);
                  });

                  offlineTimeAverage = offlineTimeTotal / time?.devices.length;
                }
                yValues.push(offlineTimeAverage);

                totalDevicesOfflinePercentages += offlineTimeAverage;
              });
              setTimeSeriesData(tableList);
            }

            // Set the y value of each tick on
            // x axis.
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          } else {
            setSeries([]);
            setHasData(false);
            setTimeSeriesData([]);
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (filterDate[0] && filterDate[1] && pastRange) {
      setShowCustomRange(false);
      const { startDate, endDate } = Utils.processFilterDates(filterDate);
      fetchData(0, duration, devices, startDate, endDate);
    } else {
      pastRange && fetchData(pastRange, duration, devices);
    }
  }, [
    pastRange,
    duration,
    devices,
    custOrgDataRef?.current?.orgId,
    filterDate,
  ]);

  useLayoutEffect(() => {
    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '98%',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [
    pastRange,
    duration,
    devices,
    custOrgDataRef?.current?.orgId,
    filterDate,
  ]);

  const handleDateChange = (data) => {
    setShowDateTimeModal(false);
    const date = data.selectedDate;
    setFilterDate(date);
  };

  return (
    <div>
      <Row className="analytics-row view-details">
        <Section
          analyticsTitle={
            id == 1
              ? constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE
              : constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_TITLE
          }
          analyticsDescription={
            id == 1
              ? constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_DESCRIPTION
              : constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_DESCRIPTION
          }
          topRightLinks={topRightLinks}
        >
          {showLoader ? (
            <div className="no-data-available">
              <SiteSpinner height="50px" width="50px"></SiteSpinner>
            </div>
          ) : (
            <div>
              {hasData ? (
                <SingleColumnLayout chart={chartContent} />
              ) : (
                defaultValue
              )}
            </div>
          )}
          {/* <div className="filterDate-image">
        <DatePicker
          ref={calendarRef}
          range
          minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
          maxDate={new Date()}
          className="rmdp-mobile customized-datepicker"
          value={filterDate}
          onChange={setFilterDate}
          //onClose={calendarRef?.current?.closeCalendar()}
          mobileButtons={[
            {
              type: 'button',
              className: 'rmdp-button rmdp-action-button',
              onClick: () => setFilterDate({}),
            },
          ]}
          render={<CustomRangePicker openCalendar value />}
        />
      </div> */}
          <SiteModal
            modalTitle="Select Date Range"
            showModal={showDateTimeModal}
            hideModal={() => {
              setShowDateTimeModal(false);
            }}
            classes="date-time-picker-modal-range"
            size="sm"
          >
            <DateTimePicker
              rangeShow={true}
              date={filterDate}
              onSubmit={handleDateChange}
            />
          </SiteModal>
        </Section>
      </Row>
      <Row className="analytics-row">
        <div className={`section-wrapper default-bg`}>
          <CamAvailableListTable columns={columnsData} data={timeSeriesData} />
        </div>
      </Row>
    </div>
  );
};

export default CameraAvailableDetails;
