import React, { useEffect, useRef, useState } from 'react';
import 'moment-timezone';
import '../../assets/css/timeline.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStream,
  getRemoteStreamAudio,
  setRemoteStream,
  setRemoteStreamAudio,
  setIsActiveStream,
  setIsReloadedStream,
  getStreamBitmapData,
  getOfferRecivedStatus,
  setOfferrecived,
} from '../../store/reducers/StreamingReducer';
import { publishWithMQTT } from '../../utils/connection/mqttConnection';
import { getAccountId } from '../../store/reducers/AccountReducer';
import store from '../../store/Store';
import { Utils, constants } from '../../helpers';
import RegionSelect from 'react-region-select';
import useEventsStore from '../../store/EventsStore';
import { XCircleFill } from 'react-bootstrap-icons';
import { getAllDevicesData } from '../../store/AccountStoreIDB';

const TimelineControlsLive = ({
  muteAudio,
  showFetchImageGrid,
  hideFetchImageGrid,
  callCountAPI,
  deviceId,
  isLicenseExpired,
  deviceInformation,
}) => {
  const remoteStream = useSelector(getRemoteStream);
  const remoteStreamAudio = useSelector(getRemoteStreamAudio);
  const [deviceDetails, setDeviceDetails] = useState(deviceInformation);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);
  const bitmapObject =
    store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const offerStatus = useSelector(getOfferRecivedStatus);
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  const dispatch = useDispatch();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const intervalRef = useRef();

  // Grid related state
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegions] = useState();
  const [finalCoordinates, setFinalCoordinates] = useState({});
  const [isRegionChanging, setIsRegionChanging] = useState(true);
  const [videoWidth, setVideoWidth] = useState();
  const [refResolution, setRefResolution] = useState();
  const {
    setSnapshotCoordinate,
    setSelectedRegion,
    getSelectedRegion,
    setRegion,
    getRegion,
  } = useEventsStore();
  const [devicesDetails, setDevicesDetails] = useState([]);

  useEffect(() => {
    if (offerStatus) {
      clearInterval(intervalRef?.current);
    }
  }, [offerStatus]);

  useEffect(() => {
    dispatch(setOfferrecived(false));

    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();

    return () => {
      clearInterval(intervalRef?.current);
      dispatch(setOfferrecived(false));
    };
  }, []);

  useEffect(() => {
    const deviceStatus = Utils.getDeviceStatus(
      deviceInformation?.deviceStatus,
      deviceInformation?.connectionStatus,
    );
    if (
      deviceInformation &&
      deviceStatus?.toLowerCase() !==
        deviceDetails?.connectionStatus?.toLowerCase()
    ) {
      setDeviceDetails(deviceInformation);
    }
  }, [JSON.stringify(deviceInformation)]);

  useEffect(() => {
    dispatch(setRemoteStream(null));
    dispatch(setIsActiveStream(false));
    dispatch(setRemoteStreamAudio(null));
    const displayDeviceStatus = Utils.getDeviceStatus(
      deviceDetails?.deviceStatus,
      deviceDetails?.connectionStatus,
    );
    if (mqttConnection) {
      if (
        !offerStatus &&
        !isLicenseExpired &&
        displayDeviceStatus?.toLowerCase() ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        Utils.vmsLogger().log('LiveStream: Initial call for offer send');
        publishWithMQTT(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId,
        );
        const id = setInterval(() => {
          Utils.vmsLogger().log('LiveStream: Retrying for offer send');
          publishWithMQTT(
            platformDetails.mqtt,
            platformDetails.p2p_server,
            deviceDetails,
            accountId,
          );
        }, 10000);
        intervalRef.current = id;
      }
    }
  }, [mqttConnection, isLicenseExpired, deviceDetails?.connectionStatus]);

  useEffect(() => {
    try {
      if (remoteStreamAudio && remoteStreamAudio !== null) {
        const remoteAudio = remoteAudioRef.current;
        remoteAudio.srcObject = remoteStreamAudio;
      }
    } catch (error) {}
    return () => {
      dispatch(setIsReloadedStream(true));
    };
  }, [remoteStreamAudio]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (selectedRegion && refResolution) {
      const divResolutionWidth = document.getElementById('canvas').offsetWidth;
      const divResolutionHeight =
        document.getElementById('canvas').offsetHeight;
      const proportionWidth = parseInt(refResolution[0]) / divResolutionWidth; // guess ref resolution is 2592 x 1520
      const proportionHeight = parseInt(refResolution[1]) / divResolutionHeight; // guess ref resolution is 2592 x 1520

      const bottomLeftX =
        proportionWidth * ((divResolutionWidth / 100) * selectedRegion[0].x);
      const bottomLeftY =
        proportionHeight *
        ((divResolutionHeight / 100) *
          (selectedRegion[0].y + selectedRegion[0].height));
      const topRightX =
        proportionWidth *
        ((divResolutionWidth / 100) *
          (selectedRegion[0].x + selectedRegion[0].width));
      const topRightY =
        proportionHeight * ((divResolutionHeight / 100) * selectedRegion[0].y);

      const finalCoordinateObj = {
        bottomLeftX,
        bottomLeftY,
        topRightX,
        topRightY,
      };
      setFinalCoordinates(finalCoordinateObj);
    }
  }, [selectedRegion, videoWidth]);

  useEffect(() => {
    if (!isRegionChanging && Object.keys(finalCoordinates).length > 0) {
      setSnapshotCoordinate(finalCoordinates);
      callCountAPI();
    } else {
      if (!isRegionChanging) {
        callCountAPI();
      }
    }
  }, [isRegionChanging, finalCoordinates]);

  useEffect(() => {
    if (deviceId && devicesDetails.length) {
      const selectedChildDevice = devicesDetails.find(
        (device) => device?.deviceId === deviceId,
      );
      if (selectedChildDevice) {
        const referenceResolution =
          selectedChildDevice?.properties?.['reference-resolution'];
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, [devicesDetails]);

  useEffect(() => {
    if (showFetchImageGrid === false) {
      setSelectedRegions(null);
      setRegions([]);
      setFinalCoordinates({});
    } else {
      const ele = document.getElementById('canvas');
      if (ele || remoteStream?.active) {
        const divResolutionWidth = ele.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    }
  }, [showFetchImageGrid, remoteStream]);

  const handleWindowSizeChange = () => {
    try {
      const divElement = document.getElementById('canvas');
      if (divElement) {
        const divResolutionWidth = divElement.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    } catch (err) {
      Utils.vmsLogger().log(err);
    }
  };

  const resetObjects = () => {
    hideFetchImageGrid();
    setSelectedRegions(null);
    setSelectedRegion(null);
    setRegions([]);
    setRegion([]);
    setFinalCoordinates({});
    setSnapshotCoordinate({});
  };

  const onChange = (regions) => {
    setRegions(regions);
    setSelectedRegions(regions);
    setSelectedRegion(regions);
    setRegion(regions);
    handleRemoveSelected();
  };

  const handleRemoveSelected = () => {
    if (selectedRegion) {
      const updatedRegions = regions?.filter(
        (region) => region !== selectedRegion,
      );
      setRegions(updatedRegions);
      setSelectedRegions(null);
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      if (isRegionChanging) {
        setIsRegionChanging(false);
      }
      return (
        <div class="iconset">
          <XCircleFill
            size={24}
            onClick={resetObjects}
            style={{ cursor: 'pointer' }}
          />
        </div>
      );
    } else {
      setIsRegionChanging(true);
    }
  };

  return (
    <div className="remote-view-wrapper">
      <video
        id="video"
        ref={remoteVideoRef}
        autoPlay={true}
        playsInline={true}
        muted={true}
      />
      <audio
        id="audio"
        ref={remoteAudioRef}
        autoPlay={true}
        playsInline={true}
        controls={false}
        muted={muteAudio}
      ></audio>
      <canvas
        id="canvas"
        width={bitmapObjectWidth}
        height={bitmapObjectHeight}
      ></canvas>

      {showFetchImageGrid && (
        <div className="region-select-main">
          <RegionSelect
            id="region-select"
            maxRegions={1}
            regions={getRegion()}
            selectedRegion={getSelectedRegion()}
            regionStyle={{
              background: `${getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}70`,
              zIndex: 70,
            }}
            onChange={onChange}
            regionRenderer={regionRenderer}
            className="region-select-main-outer"
            style={{
              width: videoWidth,
            }}
            constraint={true}
          >
            <div class="grid-image-fetch"></div>
          </RegionSelect>
        </div>
      )}
    </div>
  );
};

export default TimelineControlsLive;
