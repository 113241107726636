import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { MuuriComponent } from 'muuri-react';
import './LiveGridStructure.scss';
import {
  getIsLeftMenuOpen,
  getSelectedView,
  getViewList,
  setSelectedView,
  setViewList,
} from '../../../store/reducers/ViewsReducer';
import { Utils, constants } from '../../../helpers';
import ImageGridItem from './ImageGridItem';
import EditCamera from './EditCamera';
import { SiteSpinner } from '../../../components/common';

const ImageGridStructure = ({
  moveTimeline,
  liveSnapshot,
  time,
  cdnValue,
  timeZone,
  orgDetails,
  devicesData,
  currentViewDeviceList,
  setCurrentViewDeviceList,
  snapshotURL,
  snapshotImages,
  selectedEventTime,
  fullscreen,
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  // const [orgDetails, setOrgDetails] = useState();
  const viewList = useSelector(getViewList);
  const selectedView = useSelector(getSelectedView);
  // const currentViewDeviceList = useSelector(getCurrentViewDeviceList);
  // const [devicesData, setDevicesData] = useState([]);
  const isLeftMenuOpen = useSelector(getIsLeftMenuOpen);
  const itemCount = currentViewDeviceList?.length || 0;
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [isChangedSize, setIsChangedSize] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (devicesData && devicesData.length) {
        setLoading(false);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const containerElem = document.getElementsByClassName(
      'camera-tile-container',
    )?.[0];
    if (containerElem) {
      containerElem.style.width = '100px';
      containerElem.style.height = '100px';
      const rowCol = Utils.getGridItemRowColumn(itemCount);
      const styles = calculateDimensions(...rowCol);
      containerElem.style.width = styles.rowWidth + 'px';
      containerElem.style.height = styles.columnHeight + 'px';
    }
    const timelineElem =
      document.getElementsByClassName('multiview-timeline')?.[0];
    const mainHeaderElem = document.getElementsByClassName(
      'device-wall-main-container-header',
    )?.[0];
    if (timelineElem && mainHeaderElem) {
      timelineElem.style.width = mainHeaderElem.offsetWidth + 'px';
    }
    window.dispatchEvent(new Event('resize'));
    setIsChangedSize(!isChangedSize);
  }, [
    currentViewDeviceList,
    isLeftMenuOpen,
    itemCount,
    fullscreen,
    document.getElementsByClassName('main-content-container')?.[0]
      ?.clientHeight,
    document.getElementsByClassName('main-content-container')?.[0]?.clientWidth,
  ]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setIsChangedSize(!isChangedSize);
    }, 100);
  }, []);

  useEffect(() => {
    const mainContainerElem = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    const containerElem = document.getElementsByClassName(
      'camera-tile-container',
    )?.[0];
    if (mainContainerElem && containerElem) {
      const marginTop =
        mainContainerElem.clientHeight - containerElem.clientHeight - 20;
      containerElem.style.marginTop = marginTop / 2 + 'px';
    }
  }, [isChangedSize, fullscreen]);

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.find(
        (device) => device.deviceId === deviceId,
      );
      return deviceData;
    }
    return null;
  };

  const handleDragStart = (item) => {
    setDraggingItemId(item.getKey());
  };

  const handleDragEnd = async () => {
    setDraggingItemId(null);
    if (gridRef.current) {
      const reorderedDeviceIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      setCurrentViewDeviceList(reorderedDeviceIds);
      const updatedView = Utils.getUpdatedViewPayload(
        selectedView,
        reorderedDeviceIds.filter((a) => !a.includes('edit')),
      );
      const response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        updatedView,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        },
      );
      const resData = response?.data;

      if (resData?.meta.code === 200) {
        dispatch(setSelectedView(updatedView));
        const updatedViewList = viewList.map((view) => {
          if (view.viewId === selectedView.viewId) {
            return updatedView;
          } else {
            return view;
          }
        });
        dispatch(setViewList(updatedViewList));
      }
    }
  };

  const calculateDimensions = (rows, columns) => {
    const container = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    if (!container) return; // Ensure container exists before proceeding.

    const totalContainerWidth = container.clientWidth || 0;
    const containerHeight = container.clientHeight || 0;

    if (!totalContainerWidth || !containerHeight) return; // If no dimensions are found, return early.

    // Adjust width based on left menu visibility
    const availableWidth = isLeftMenuOpen
      ? totalContainerWidth - 100
      : totalContainerWidth; // Assuming the left menu is 300px wide
    const aspectRatio = 16 / 9; // Default aspect ratio
    const rowWidth = availableWidth / columns; // Width of each column
    const columnHeight = containerHeight / rows; // Height of each row

    let tileWidth = rowWidth;
    let tileHeight = tileWidth / aspectRatio;

    // Adjust width and height to maintain the aspect ratio within the container
    if (tileHeight > columnHeight) {
      tileHeight = columnHeight;
      tileWidth = tileHeight * aspectRatio;
    }

    return {
      rowWidth: tileWidth * columns,
      columnHeight: tileHeight * rows,
    };
  };

  return (
    <div className={`camera-tile-container`}>
      {loading ? (
        <div className="loading-screen" style={{ position: 'absolute' }}>
          <SiteSpinner />
          <div className="simple-label">
            {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
          </div>
        </div>
      ) : (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={currentViewDeviceList.length > 1}
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          layoutOnInit={false}
          layoutDuration={0}
          layoutEasing={'linear(0, 0, 0)'}
          hideDuration={600}
          hideEasing={'linear(0, 0, 0)'}
          showDuration={0}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          visibleStyles={{
            opacity: 0,
          }}
          hiddenStyles={{
            opacity: 0,
          }}
        >
          {currentViewDeviceList.map((deviceId, index) => (
            <div
              key={deviceId}
              onMouseEnter={() => setHoveredDeviceId(deviceId)}
              onMouseLeave={() => setHoveredDeviceId(null)}
              className={`item-grid ${
                draggingItemId !== deviceId ? '' : 'dragged-item'
              }
              ${hoveredDeviceId === deviceId ? 'add-hover' : ''}
            `}
              style={Utils.getResponsiveStyleProps(itemCount)}
            >
              {getDeviceInfo(deviceId) ? (
                <ImageGridItem
                  key={deviceId}
                  layout={deviceId}
                  device={getDeviceInfo(deviceId)}
                  deviceId={deviceId}
                  moveTimeline={moveTimeline}
                  liveSnapshot={liveSnapshot}
                  time={time}
                  cdnValue={cdnValue}
                  camera={index + 1}
                  timeZone={timeZone}
                  snapshotImages={snapshotImages}
                  imageURL={snapshotURL?.[deviceId]}
                  selectedEventTime={selectedEventTime}
                />
              ) : (
                <EditCamera />
              )}
            </div>
          ))}
        </MuuriComponent>
      )}
    </div>
  );
};

export default ImageGridStructure;
