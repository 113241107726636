import AvatarBW from './AvatarBW';
import { constants, Utils } from '../../../helpers';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/DefaultAvatarUser.svg';
import './AvatarNameDetails.scss';
import React from 'react';

const AvatarNameDetails = React.memo(
  ({ accountId, size = 'small', allUsers }) => {
    const user = allUsers?.[accountId];

    const checkAvatarURLValid = (user) => {
      return (
        user?.image?.url &&
        user?.image?.expiry &&
        !(user?.image?.expiry < Date.now())
      );
    };

    const avatarValueType = checkAvatarURLValid(user) ? 'icon' : 'text';

    const avatarValue = checkAvatarURLValid(user)
      ? user?.image?.url
      : Utils?.getInitialsFromFullName(user?.name);

    return (
      <div className={`avatar-name-wrapper ${size}`}>
        {user ? (
          <>
            <AvatarBW
              valueType={avatarValueType}
              value={avatarValue}
              size={size}
              avatarStyle="roundedCircle"
            />
            <span
              className={`initials-name ${size}`}
            >{`${user?.firstName} ${user?.lastName}`}</span>
          </>
        ) : (
          <>
            <UserIcon className={`user-icon ${size}`} />
            <span
              className={`initials-name ${size}`}
            >{`${constants.TOOLS_PAGE_REMOVED_USER}`}</span>
          </>
        )}
      </div>
    );
  },
);

export default AvatarNameDetails;
