import React, { useEffect, useState } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import megatron from './assets/css/megatron.scss';
import { withTranslation } from 'react-i18next';
import { AppDefaults, constants } from './helpers';
import { Device } from './pages/devices';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/App.scss';
import './assets/css/styles.scss';
import megatronFavicon from './assets/images/megatronFavicon.png';
import BrandFavicon from './assets/images/BrandFavicon.svg';
import { Provider } from 'react-redux';
import Store from './store/Store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './auth/Keycloak';
import { checkMQTTConnection } from './utils/connection/mqttConnection';
import { checkWSSConnection } from './utils/connection/wssConnection';
import {
  setMQTTConnection,
  setWSSConnection,
} from './store/reducers/StreamingReducer';
import colorCodes from './assets/css/themes';
import useEventSelectionStore from './store/EventSelectionStore';
import { SiteSpinner } from './components/common';
import routes from './routes/routes';
// TODO: See line 344 below.  Only delete this import if it will never be used at all
// import reportWebVitals from './reportWebVitals';

// IMPORTANT: PLEASE VERIFY PROJECT ONCE AFTER MADE ANY CHANGE IN APP.JS

const AppRoutes = () => {
  return useRoutes(routes);
};
const App = () => {
  const { setNavigatedFromCameraDetail } = useEventSelectionStore();
  const persistor = persistStore(Store);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    localStorage.setItem('isSession', 0);
    const convertedUrl = new URL(window?.location?.href);

    if (convertedUrl?.searchParams.has('searchtext')) {
      const cleanUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname;
      window.location.href = cleanUrl;
      setNavigatedFromCameraDetail(false);
    }

    colorCodes.colors.map((colorCode) =>
      document.documentElement.style.setProperty(
        `--${colorCode}`,
        megatron[colorCode],
      ),
    );
  }, []);

  const onEvent = (event, error) => {
    if (typeof error !== 'undefined') {
      switch (event) {
        case 'onAuthSuccess':
          localStorage.setItem('authenticated', true);
          break;

        case 'onAuthLogout':
        case 'onTokenExpired':
          localStorage.setItem('authenticated', false);
          localStorage.setItem('vmsAuthToken', null);
          break;

        default:
      }
    }

    // If user is logged in - create mqtt connection
    if (keycloak?.authenticated === true) {
      if (!checkMQTTConnection()) {
        Store.dispatch(setMQTTConnection(false));
      }
      if (!checkWSSConnection()) {
        Store.dispatch(setWSSConnection(false));
      }
    }
  };
  return (
    <>
      {showLoader && (
        <div
          className="d-flex flex-column align-items-center position-absolute top-50"
          style={{ left: '47%' }}
        >
          <SiteSpinner height="50px" width="50px" backgroundColor="#f37321" />
          <div className="mt-2 text-dark">{constants.LOADING}</div>
        </div>
      )}
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={onEvent}
            onTokens={({ token }) => {
              setShowLoader(false);
              localStorage.setItem('vmsAuthToken', token);
              if (token) {
                localStorage.setItem('sessionAlive', '0');
              }
            }}
          >
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </ReactKeycloakProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default withTranslation()(App);
