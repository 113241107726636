import { Col, Container, Row } from 'react-bootstrap';
import {
  ContextMenu,
  DateTimePickerBW,
  Header,
  ListTableServerSide,
  PrimaryButton,
  SiteSpinner,
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { AppDefaults, constants, Utils } from '../../helpers';
import { usePoliciesStore } from '../../store/policiesStore';
import { RxPlus } from 'react-icons/rx';
import './ToolsList.scss';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ToolsSvg } from '../../assets/images/tools.svg';
import { ReactComponent as CalendarEventIcon } from '../../assets/images/CalendarEventIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/SearchLicensePlate.svg';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Box } from '@mui/material';
import { HiOutlineCalendar, HiOutlineFolderOpen } from 'react-icons/hi';
import moment from 'moment-timezone';
import { IoIosSearch } from 'react-icons/io';
import axios from 'axios';
import AvatarNameDetails from './bandwidth/AvatarNameDetails';
import DeleteBWEstimatePopup from './bandwidth/DeleteBWEstimatePopup';
import SaveBWChangesForm from './bandwidth/SaveBWChangesForm';

const gridFields = [
  {
    field: 'name',
    sortableField: 'name',
    fieldType: 'text',
    displayName: constants.TOOLS_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME,
  },
  {
    field: 'type',
    sortableField: 'type',
    fieldType: 'text',
    displayName: constants.TOOLS_PAGE_GRID_COLUMN_HEADER_DISPLAY_TYPE,
  },
  {
    field: 'createdBy',
    sortableField: 'createdBy',
    fieldType: 'text',
    displayName: constants.TOOLS_PAGE_GRID_COLUMN_HEADER_DISPLAY_CREATED_BY,
  },
  {
    field: 'updatedBy',
    sortableField: 'updatedBy',
    fieldType: 'text',
    displayName: constants.TOOLS_PAGE_GRID_COLUMN_HEADER_DISPLAY_MODIFIED_BY,
  },
  {
    field: 'updatedDate',
    sortableField: 'updatedDate',
    fieldType: 'text',
    displayName: constants.TOOLS_PAGE_GRID_COLUMN_HEADER_DISPLAY_MODIFIED,
  },
  {
    field: 'action',
    sortableField: null,
    fieldType: 'icon',
    displayContent: <></>,
  },
];

const getColDefs = () => {
  const maxWidthMap = {
    name: 200,
    type: 200,
    createdBy: 200,
    updatedBy: 200,
    updatedDate: 250,
    action: 54,
  };

  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    sortableField: gridField.sortableField,
    displayName:
      gridField.fieldType === 'text'
        ? gridField.displayName
        : gridField.displayContent,
    cellMaxWidth: maxWidthMap[gridField.field],
  }));
};

const ToolsList = () => {
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [bandwidthEstimates, setBandwidthEstimates] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [bandwidthEstimatesMetaData, setBandwidthEstimatesMetaData] =
    useState(null);
  const [columnDefs, setColumnDefs] = useState([...getColDefs()]);
  const initialBWMetaData = {
    pageNo: 0,
    pageSize: AppDefaults.BANDWIDTH_PAGE_SIZE,
    searchString: '',
    orderBy: 'updatedDate',
    sortOrder: 'desc',
  };
  const [currentBWMetaData, setCurrentBWMetaData] = useState({
    ...initialBWMetaData,
  });
  // searchStringForRef is used to show no search result found screen
  const [searchStringForRef, setSearchStringForRef] = useState('');
  const [rowData, setRowData] = useState([]);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const userData = getLoggedInUserData();
  const orgId = userData?.orgId;
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const zone = moment.tz.guess();
  const abbr = moment.tz(zone).format('z');
  const [isReFetchData, setIsRefetchData] = useState(false);
  const [orgUsers, setOrgUsers] = useState([]);
  const [showDeleteBWEstimateModal, setShowDeleteBWEstimateModal] =
    useState(false);
  const [selectedBWEstimateId, setSelectedBWEstimateId] = useState('');
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef?.current &&
        !calendarRef?.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(() => {
    const currentColDefs = [...columnDefs];

    currentColDefs.every((colDef) => {
      if (colDef?.field?.toUpperCase() === 'ACTION') {
        colDef.clickHandler = () => {
          // setShowFilterIncidentsModal(true);
        };
        return false;
      }
      return true;
    });

    setColumnDefs([...currentColDefs]);
  }, [orgId]);

  const fetchBandwidthEstimates = async (route) => {
    if (!route) return;
    const res = await axios.get(route, Utils.requestHeader());
    const response = res?.data;

    // Set organizations if request is successful
    if (response?.meta?.code === 200) {
      const data = response?.data;
      if (data?.accounts && Object.keys(data?.accounts)?.length) {
        setOrgUsers({ ...orgUsers, ...data?.accounts });
      } else {
        setOrgUsers([]);
      }

      if (data?.bandwidths?.length > 0) {
        if (data.pageNumber === 0) {
          setBandwidthEstimates(data.bandwidths);
        } else {
          setBandwidthEstimates([...bandwidthEstimates, ...data.bandwidths]);
        }
        delete data.bandwidths;
        setBandwidthEstimatesMetaData(data);
      } else {
        setBandwidthEstimates([]);
      }
    } else {
      setBandwidthEstimates([]);
    }
  };

  const fetchAllBandwidths = async (orgId) => {
    const { orderBy, sortOrder, pageNo, pageSize, searchString } =
      currentBWMetaData;
    setSearchStringForRef(searchString);
    const dateTime = getDateInEpochTime(selectedDateRange);
    const params = `?orderBy=${orderBy}&isAsc=${
      sortOrder === 'asc'
    }&name=${searchString}&page=${pageNo}&size=${pageSize}&startTime=${
      dateTime?.[0] || ''
    }&endTime=${dateTime?.[1] || ''}`;
    await fetchBandwidthEstimates(
      `partner/orgs/${orgId}/bandwidth/estimate${params}`,
    );
    setShowLoader(false);
  };

  const getDateInEpochTime = (date) => {
    if (date?.length > 0) {
      const startDate =
        moment(new Date(date[0])).format('YYYY-MM-DD') + ' 12:00:00 AM';
      const endDate =
        moment(new Date(date[1])).format('YYYY-MM-DD') + ' 11:59:59 PM';

      const startEpoch = moment(startDate, 'YYYY-MM-DD hh:mm:ss A').valueOf();
      const endEpoch = moment(endDate, 'YYYY-MM-DD hh:mm:ss A').valueOf();

      return [startEpoch, endEpoch];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (orgId) {
      setShowLoader(true);
      fetchAllBandwidths(orgId);
    }
  }, [orgId, isReFetchData]);

  useEffect(() => {
    if (Array.isArray(bandwidthEstimates) && bandwidthEstimates.length > 0) {
      const tableRowData = getRowData(bandwidthEstimates);
      setRowData([...tableRowData]);
    }
  }, [bandwidthEstimates]);

  const menuItems = [
    {
      text: constants.TOOLS_PAGE_CONTEXT_MENU_EDIT_LABEL,
      clickHandler: (e, data, i) => {
        navigate({
          pathname: '/tools/edit.html',
          search: `?estimateId=${data.estimateId}`,
        });
      },
    },
    {
      text: constants.TOOLS_PAGE_CONTEXT_MENU_DUPLICATE_LABEL,
      clickHandler: (e, data, i) => {
        setSelectedBWEstimateId(data.estimateId);
        setShowSaveChangesModal(true);
      },
    },
    {
      text: constants.TOOLS_PAGE_CONTEXT_MENU_DELETE_LABEL,
      clickHandler: (e, data, i) => {
        setSelectedBWEstimateId(data.estimateId);
        setShowDeleteBWEstimateModal(true);
      },
    },
    {
      text: constants.TOOLS_PAGE_CONTEXT_MENU_PRINT_PDF_LABEL,
      clickHandler: (e, data, i) => {
        navigate({
          pathname: '/tools/edit.html',
          search: `?estimateId=${data.estimateId}&print=true`,
        });
      },
    },
  ];

  const getRowData = (bandwidthEstimates) => {
    let bandwidthEstimateProps = {};
    const rowData = [];

    if (!Array.isArray(bandwidthEstimates)) return;

    bandwidthEstimates.forEach((bandwidthEstimate) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'NAME':
            bandwidthEstimateProps[def?.field] = bandwidthEstimate[def?.field];
            break;

          case 'TYPE':
            bandwidthEstimateProps[def?.field] = bandwidthEstimate[def?.field];
            break;

          case 'CREATEDBY':
            bandwidthEstimateProps[def?.field] = (
              <AvatarNameDetails
                allUsers={orgUsers}
                accountId={bandwidthEstimate[def?.field]}
              />
            );
            break;

          case 'UPDATEDBY':
            bandwidthEstimateProps[def?.field] = (
              <AvatarNameDetails
                allUsers={orgUsers}
                accountId={bandwidthEstimate[def?.field]}
              />
            );
            break;

          case 'UPDATEDDATE':
            bandwidthEstimateProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in bandwidthEstimate
                  ? `${moment(parseInt(bandwidthEstimate?.updatedDate)).format(
                      AppDefaults.BANDWIDTH_DATE_FORMAT,
                    )} ${abbr}`
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            break;

          case 'ACTION':
            bandwidthEstimateProps[def?.field] = (
              <div
                key={`action-field-${bandwidthEstimate?.estimateId}`}
                className="action-field"
              >
                <ContextMenu
                  menuId={'tools-list-menu'}
                  menuItems={menuItems}
                  menuMinWidth="126px"
                  customItemTextClass="tools-item-text"
                  customData={{ estimateId: bandwidthEstimate.estimateId }}
                />
              </div>
            );
            bandwidthEstimateProps[def?.sortableField] = null;
            break;

          default:
            if (def?.field in bandwidthEstimate) {
              bandwidthEstimateProps[def?.field] =
                bandwidthEstimate[def?.field];
            }
        }
      });

      bandwidthEstimateProps.rowId = bandwidthEstimate.estimateId;
      rowData.push(bandwidthEstimateProps);
      bandwidthEstimateProps = {};
    });

    return rowData;
  };

  const goToBandwidthEstimate = () => {
    navigate('/tools/new.html');
  };

  const handleLoadMore = () => {
    setShowLoader(true);
    setCurrentBWMetaData({
      ...currentBWMetaData,
      pageNo: currentBWMetaData.pageNo + 1,
    });
    setIsRefetchData(!isReFetchData);
  };

  const handleSortClick = (field, sortOrder) => {
    setCurrentBWMetaData({
      ...currentBWMetaData,
      pageNo: 0,
      orderBy: field,
      sortOrder: sortOrder,
    });
    setIsRefetchData(!isReFetchData);
  };

  const handleSearchChange = (e) => {
    const inputValue = e?.target?.value;
    setCurrentBWMetaData({
      ...currentBWMetaData,
      pageNo: 0,
      searchString: inputValue,
    });
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsRefetchData(!isReFetchData);
    }
  };

  const handleRowClick = (id) => {
    navigate({
      pathname: '/tools/edit.html',
      search: `?estimateId=${id}`,
    });
  };

  const handleSavedSuccess = () => {
    setIsRefetchData(!isReFetchData);
    hideSaveChangesModal();
  };

  const hideSaveChangesModal = () => {
    setShowSaveChangesModal(false);
    setSelectedBWEstimateId('');
  };

  const handleDeletedSuccess = async () => {
    setIsRefetchData(!isReFetchData);
    hideDeleteModal();
  };

  const hideDeleteModal = () => {
    setShowDeleteBWEstimateModal(false);
    setSelectedBWEstimateId('');
  };

  const handleDateChange = (data) => {
    setShowDatePicker(false);
    const date = data.selectedDate;
    setSelectedDateRange(date);
    setCurrentBWMetaData({
      ...currentBWMetaData,
      pageNo: 0,
    });
    setIsRefetchData(!isReFetchData);
  };

  const handleDateCancel = () => {
    setShowDatePicker(false);
  };

  const getSelectedDateValue = (date) => {
    if (date?.length > 0) {
      const startDate = moment(new Date(date[0])).format('ll');
      const endDate = moment(new Date(date[1])).format('ll');
      return startDate === endDate ? startDate : startDate + ' - ' + endDate;
    } else {
      return constants.TOOLS_PAGE_SELECT_CREATION_DATE_RANGE;
    }
  };

  const handleClearSearch = () => {
    setCurrentBWMetaData({ ...currentBWMetaData, pageNo: 0, searchString: '' });
    setSearchStringForRef('');
    setSelectedDateRange([]);
    setIsRefetchData(!isReFetchData);
  };

  return (
    <div className="App">
      <Header />
      <PageWrapper className="mw-100 tools-list-container">
        <div className="page-header">
          <Row>
            <Col md={6} lg={6} xl={6} xs={12} className="page-title text-start">
              {constants.TOOLS_PAGE_TITLE}
            </Col>
            <Col
              md={6}
              lg={6}
              xl={6}
              xs={12}
              className="text-end page-header-buttons"
            >
              {getLoggedInUserPolicies()?.manage_bandwidth_estimate && (
                <PrimaryButton
                  className="btn btn-primary"
                  type="button"
                  width="auto"
                  onClick={goToBandwidthEstimate}
                >
                  <RxPlus
                    className="plus-icon-btn"
                    strokeWidth={'1.5px'}
                    size={13.33}
                  />
                  {constants.BANDWIDTH_ESTIMATE_BUTTON_TITLE}
                </PrimaryButton>
              )}
            </Col>
          </Row>
        </div>
        {(bandwidthEstimates.length !== 0 ||
          searchStringForRef?.length > 0 ||
          selectedDateRange?.length > 0) && (
          <div className="search-wrapper">
            <Container className="h-100 mw-100 search-wrapper-container">
              <div className="search-container">
                <IoIosSearch className="search-icon" />
                <input
                  type="text"
                  placeholder={constants.TOOLS_PAGE_SEARCH_BY_NAME_PLACEHOLDER}
                  className="search-input"
                  value={currentBWMetaData?.searchString || ''}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchKeyDown}
                />
              </div>
              <div className={`date-range-filter-container`}>
                <div
                  className={`date-range-filter ${
                    showDatePicker ? 'active' : ''
                  }`}
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                  }}
                >
                  <div className="date-range-text">
                    {getSelectedDateValue(selectedDateRange)}
                  </div>
                  <CalendarEventIcon />
                </div>
                {showDatePicker && (
                  <div className="date-picker-container" ref={calendarRef}>
                    <DateTimePickerBW
                      rangeShow={true}
                      date={selectedDateRange}
                      onSubmit={handleDateChange}
                      onCancel={handleDateCancel}
                    />
                  </div>
                )}
              </div>
            </Container>
          </div>
        )}
        <div
          className={`list-block mt-3 ${
            bandwidthEstimates.length === 0 &&
            searchStringForRef?.length === 0 &&
            selectedDateRange?.length === 0
              ? 'no-bandwidth-available'
              : ''
          }`}
        >
          <Container className="h-100 mw-100">
            {bandwidthEstimates.length === 0 && showLoader ? (
              <div className="position-absolute loader-container top-50">
                <SiteSpinner height="50px" width="50px" />
                <div className="mt-2 text-dark">{constants.LOADING}</div>
              </div>
            ) : (
              <>
                {showLoader && (
                  <div className="position-absolute loader-container top-50">
                    <SiteSpinner height="50px" width="50px" />
                    <div className="mt-2 text-dark">{constants.LOADING}</div>
                  </div>
                )}
                {bandwidthEstimates.length === 0 &&
                (searchStringForRef?.length > 0 ||
                  selectedDateRange?.length > 0) ? (
                  <div className="no-tools-container">
                    <div className="search-icon-wrapper">
                      <SearchIcon />
                    </div>
                    <div className="bandwidth-no-content-title mt-3">
                      {constants.BANDWIDTH_NO_RESULT_TITLE_TEXT}
                    </div>
                    <div className="bandwidth-no-content mt-2">
                      {constants.BANDWIDTH_NO_RESULT_HELPER_TEXT}
                    </div>
                    <div
                      className="bandwidth-new-estimate"
                      onClick={handleClearSearch}
                    >
                      {constants.BANDWIDTH_NO_RESULT_CLEAR_SEARCH_BUTTON}
                    </div>
                  </div>
                ) : bandwidthEstimates.length > 0 ? (
                  <div className="tools-table-container">
                    {Array.isArray(rowData) && (
                      <LazyLoadComponent>
                        <ListTableServerSide
                          colsDef={columnDefs}
                          colSorting={true}
                          rowData={rowData}
                          cssClasses="tools-table"
                          rowClickDisabled={false}
                          rowClickHandler={handleRowClick}
                          sortableField="sortableField"
                          onSortClick={handleSortClick}
                          hasMore={
                            bandwidthEstimatesMetaData
                              ? !bandwidthEstimatesMetaData.last
                              : false
                          }
                          loadMore={handleLoadMore}
                          noDataContent={
                            <Box className="no-incidents-container">
                              <HiOutlineFolderOpen
                                size={32}
                                className="folder-icon"
                                style={{
                                  height: '32px',
                                  width: '32px',
                                  color: '#111418',
                                  fontWeight: 400,
                                  flexShrink: 0,
                                  marginTop: '14px',
                                }}
                              />
                              <p className="no-incident-alert">
                                {
                                  constants.INCIDENTS_DETAILS_NO_MATCHING_INCIDENTS_TEXT
                                }
                              </p>
                            </Box>
                          }
                        />
                      </LazyLoadComponent>
                    )}
                  </div>
                ) : (
                  <div className="no-tools-container">
                    <div className="image-wrapper">
                      <ToolsSvg />
                    </div>
                    <div className="bandwidth-no-content mt-3">
                      {constants.BANDWIDTH_NO_CONTENT_HELPER_TEXT}
                    </div>
                    <div
                      className="bandwidth-new-estimate"
                      onClick={goToBandwidthEstimate}
                    >
                      {constants.BANDWIDTH_NEW_ESTIMATE_TEXT}
                    </div>
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
      </PageWrapper>
      {/* Save Bandwidth Estimation Popup Start */}
      {showSaveChangesModal && (
        <SaveBWChangesForm
          popupTitle={constants.BANDWIDTH_DUPLICATE_ESTIMATE_MODAL_TITLE}
          orgId={orgId}
          name={
            bandwidthEstimates?.find(
              (b) => b.estimateId === selectedBWEstimateId,
            )?.name || ''
          }
          isUpdateAPI={false}
          isAllProperties={false}
          bwEstimationDetails={bandwidthEstimates?.find(
            (b) => b.estimateId === selectedBWEstimateId,
          )}
          onHideModal={hideSaveChangesModal}
          onSavedSuccess={handleSavedSuccess}
        />
      )}
      {/* Save Bandwidth Estimation Popup End */}
      {/* Delete Bandwidth Estimation Popup Start */}
      {showDeleteBWEstimateModal && (
        <DeleteBWEstimatePopup
          orgId={orgId}
          estimateId={selectedBWEstimateId}
          onHideModal={hideDeleteModal}
          onDeletedSuccess={handleDeletedSuccess}
        />
      )}
      {/* Delete Bandwidth Estimation Popup End */}
    </div>
  );
};

export default ToolsList;
