import StepIndicator from '../StepIndicator';
import { Utils, constants, roles } from '../../../../helpers';
import '../stepsnotification.scss';
import { useRef, useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../components/common';
import sheduled from '../../../../assets/images/sheduled-noti.svg';
import plus from '../../../../assets/images/PlusIcon.svg';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import CreateEditSheduled from '../CreateEditSheduled';
import { ToggleInput } from '../../../../components/forms';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';

const Four = (props) => {
  const scollToRef = useRef();
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [showIndicator, setshowIndicator] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [index, setIndex] = useState(-1);

  const [sheduledList, setSheduledList] = useState([]);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );

  useEffect(() => {
    setTimeout(() => {
      scollToRef?.current?.scrollIntoView();
    }, 100);
  }, []);

  const moveToNextStep = () => {
    const selectedList = sheduledList?.filter(
      (itemScheduled) => itemScheduled?.isEnabled,
    );
    const nextStepData = {};
    if (props?.isEdit) {
      nextStepData['triggers'] = getStepsData()?.triggers;
      nextStepData['selectedEvents'] = getStepsData()?.selectedEvents;
      nextStepData['editItem'] = getStepsData()?.editItem;
      nextStepData['devices'] = getStepsData()?.devices;
      nextStepData['actions'] = getStepsData()?.actions;
      nextStepData['subscribers'] = getStepsData()?.subscribers;
    } else {
      nextStepData['triggers'] = props?.stepData?.triggers;
      nextStepData['selectedEvents'] = props?.stepData?.selectedEvents;
      nextStepData['devices'] = props?.stepData?.devices;
      if (props?.role !== roles.ROLE1VMS) {
        nextStepData['subscribers'] = [loggedInUserData?.accountId];
      }
    }
    if (selectedList?.length > 0) {
      nextStepData['conditions'] = selectedList;
    }
    nextStepData['locationId'] = getStepsData()?.locationId;
    setStepData(nextStepData);
    props.nextStep(nextStepData);
  };
  const createEditFlow = (status) => {
    setshowIndicator(status);
  };

  const loadEmptyState = () => {
    return (
      <div className="inner-container">
        <div className="sheduled">
          <img alt="" className="img-icon-sheduled" src={sheduled}></img>
        </div>
        <div className="step-add-data">
          {constants.NOTIFICATION_CREATE_SHEDULED_MESSAGE}
        </div>
        <div onClick={() => createEditFlow(false)} className="add-btn">
          <img alt="" className="img-icon-sheduled" src={plus}></img>
        </div>
      </div>
    );
  };

  const editSheduled = (item, index) => {
    if (item?.isEnabled) {
      setIndex(index);
      setSelectedItem(item);
      setshowIndicator(false);
    }
  };

  const updateSheduledList = async (item, isCreate, isDelete) => {
    setSelectedItem({});
    if (isDelete) {
      const filteredPeople = sheduledList.filter(
        (itemScheduled, indexList) => indexList !== index,
      );
      setIndex(-1);
      setSheduledList(filteredPeople);
      setshowIndicator(true);
    } else if (isCreate) {
      sheduledList.push(item);
      setIndex(-1);
      setSheduledList(sheduledList);
      setshowIndicator(true);
    } else {
      const updateScheduled = sheduledList?.map((itemScheduled, itemIndex) => {
        if (itemIndex === index) {
          return { ...item };
        } else {
          return { ...itemScheduled };
        }
      });
      let sheduledItems = await Promise.all(updateScheduled);
      setIndex(-1);
      setSheduledList(sheduledItems);
      setshowIndicator(true);
    }
  };

  const onHandleChangeToggle = async (item, indexItem) => {
    const list = sheduledList?.map((listItem, index) => {
      if (index === indexItem) {
        if (listItem?.isEnabled) {
          return { ...listItem, isEnabled: false };
        } else {
          return { ...listItem, isEnabled: true };
        }
      } else {
        return { ...listItem };
      }
    });
    let sheduledItems = await Promise.all(list);
    setSheduledList(sheduledItems);
  };

  const addNewSheduled = () => {
    setSelectedItem({});
    setshowIndicator(false);
  };

  const getSelectedDaysFromId = (daysId) => {
    const daysValue = [];
    const fillterDevice = Utils.getWeekDays(daysId);
    fillterDevice?.map((item, index) => {
      if (item?.isSelected) {
        daysValue.push(item?.shortName);
      }
    });
    return daysValue?.join(',');
  };

  useEffect(() => {
    if (Object.keys(getStepsData()).length !== 0) {
      const conditions =
        getStepsData()?.conditions?.length > 0
          ? getStepsData()?.conditions
          : [];
      setSheduledList([...conditions]);
    }
  }, [getStepsData()]);

  return (
    <div ref={scollToRef} className="create-notification">
      {showIndicator && <StepIndicator {...props} />}
      {showIndicator && (
        <div className="sheduled-step-four">
          <div className="step-title">
            {constants.NOTIFICATION_CREATE_SHEDULED}
          </div>
          <div className="sheduled-selection-row">
            <div className="step-title-desc">
              {constants.NOTIFICATION_CREATE_SHEDULED_SUB_TITLE}
            </div>
          </div>
          {sheduledList?.length > 0 ? (
            <div className="sheduled-parent-container">
              {sheduledList?.map((item, index) => {
                return (
                  <div className="list-sheduled" key={index}>
                    <div className="time-container ">
                      <div
                        className={`label-title ${
                          item?.isEnabled ? 'selected' : ''
                        }`}
                      >
                        {`${Utils.getHoursFromValue(item?.startTime)} -
                        ${Utils.getHoursFromValue(item?.endTime)}`}
                      </div>
                      <div className="toggle-checkbox">
                        <ToggleInput
                          changeHandler={(e) => {
                            onHandleChangeToggle(item, index);
                          }}
                          value={item?.isEnabled}
                          classes={'toggle-bg'}
                        />
                      </div>
                    </div>
                    <div className="time-container days-block ">
                      <div className="days-selected">
                        {getSelectedDaysFromId(item?.days)}
                      </div>
                      <div
                        onClick={() => editSheduled(item, index)}
                        className={`edit-text ${
                          item?.isEnabled ? '' : 'non-edit'
                        }`}
                      >
                        {constants.NEW_LOCATIONS_MARKER_EDIT_BUTTON_TITLE}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            loadEmptyState()
          )}
          {sheduledList?.length > 0 && (
            <div onClick={() => addNewSheduled()} className="add-sheduled">
              <AiOutlinePlus
                size={24}
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
              />
              <div className="add-text">
                {constants.NOTIFICATION_CREATE_ADD_NEW_SCHEDULED}
              </div>
            </div>
          )}
          <PrimaryButton
            className="adddevice-btn"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            height="44px"
            color="#FFFFFF"
            type="submit"
            disabled={sheduledList?.length === 0}
            onClick={() => moveToNextStep()}
          >
            {constants.DEVICES_CONTINUE_TEXT}
          </PrimaryButton>
          {sheduledList?.length <= 0 && (
            <PrimaryButton
              className="adddevice-btn"
              fontSize="14px"
              backgroundColor="#fff"
              height="44px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              type="submit"
              onClick={() => moveToNextStep()}
            >
              {constants.NOTIFICATION_CREATE_SKIP_BTN}
            </PrimaryButton>
          )}
        </div>
      )}
      {!showIndicator && (
        <CreateEditSheduled
          {...props}
          selectedItem={selectedItem}
          onSelectItemChange={(data, isCreate, isDelete) => {
            updateSheduledList(data, isCreate, isDelete);
          }}
          hideContainer={(status) => {
            createEditFlow(status);
          }}
        />
      )}
    </div>
  );
};

export default Four;
