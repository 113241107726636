import React, { useEffect, useRef, useState } from 'react';
import { constants, Utils } from '../../../../helpers';
import { checkUnslect } from '../../../../assets/images';
import { ImCheckboxChecked } from 'react-icons/im';
import { MdIndeterminateCheckBox } from 'react-icons/md';
import { PrimaryButton } from '../../../../components/common';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import { EventType } from '../../../../helpers/enums';
import { InputSlider, ToggleInput } from '../../../../components/forms';
import { Col, Row } from 'react-bootstrap';

const CategoryTypeIVA = ({
  isObjectSelected,
  title,
  subtitle,
  deviceInfos,
  canChangeSettings,
  capVirtualAreaIVA,
  callBackMethod,
  virtualAreaIva,
  selectedRegion,
  isDeleteTriggered,
}) => {
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const [intrusion, setIntrusion] = useState(false);
  const [disableStatus, setDisableStatus] = useState(isObjectSelected);

  const resourceList = Utils.getDeviceSettingResource(7);
  const modelData = Utils.getModalData(
    9,
    deviceInfos?.properties,
    capVirtualAreaIVA,
    resourceList[13],
  );
  const modelDataAppear = Utils.getModalData(
    10,
    deviceInfos?.properties,
    capVirtualAreaIVA,
    resourceList[13],
  );
  const modelDataloitering = Utils.getModalData(
    11,
    deviceInfos?.properties,
    capVirtualAreaIVA,
    resourceList[13],
  );
  const [intrusionMinDur, setIntrusionMinDur] = useState(
    modelData?.valueDuration,
  );
  const [entering, setEntering] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [appearDisappear, setAppearDisappear] = useState(false);
  const [appearDisappearMinDur, setAppearDisappearMinDur] = useState(
    modelDataAppear?.valueDuration,
  );
  const [loitering, setLoitering] = useState(false);
  const [loiteringMinDur, setLoiteringMinDur] = useState(
    parseInt(modelDataloitering?.valueDuration) / 60,
  );

  useEffect(() => {
    setDisableStatus(isObjectSelected);
  }, [isObjectSelected]);

  useEffect(() => {
    if (isDeleteTriggered) {
      setInitaialValue();
    }
  }, [isDeleteTriggered]);

  useEffect(() => {
    if (virtualAreaIva && virtualAreaIva?.['virtual-areas']?.length > 0) {
      const selctedObjtTypes = virtualAreaIva?.['virtual-areas']?.find(
        (item) => item?.['virtual-area-index'] == selectedRegion?.index,
      );
      if (selctedObjtTypes) {
        setIntrusion(selctedObjtTypes?.['virtual-area-intrusion'] === 'true');
        const value = selctedObjtTypes?.['virtual-area-intrusion-min-dur']
          ? parseInt(selctedObjtTypes?.['virtual-area-intrusion-min-dur'])
          : 0;
        setIntrusionMinDur(value);
        setAppearDisappear(
          selctedObjtTypes?.['virtual-area-appear-disappear'] === 'true',
        );
        setAppearDisappearMinDur(
          selctedObjtTypes?.['virtual-area-appear-disappear-min-dur']
            ? parseInt(
                selctedObjtTypes?.['virtual-area-appear-disappear-min-dur'],
              )
            : 0,
        );
        setLoitering(selctedObjtTypes?.['virtual-area-loitering'] === 'true');
        const valueLoitering = selctedObjtTypes?.[
          'virtual-area-loitering-min-dur'
        ]
          ? parseInt(selctedObjtTypes?.['virtual-area-loitering-min-dur'])
          : 0;
        setLoiteringMinDur(parseInt(valueLoitering) / 60);
        setEntering(selctedObjtTypes?.['virtual-area-entering'] === 'true');
        setExiting(selctedObjtTypes?.['virtual-area-exiting'] === 'true');
      } else {
        setInitaialValue();
      }
    }
  }, [selectedRegion]);

  const setInitaialValue = () => {
    setIntrusion(false);
    setIntrusionMinDur(modelData?.valueDuration);
    setAppearDisappear(false);
    setAppearDisappearMinDur(modelDataAppear?.valueDuration);
    setLoitering(false);
    setLoiteringMinDur(parseInt(modelDataloitering?.valueDuration) / 60);
    setEntering(false);
    setExiting(false);
  };

  useEffect(() => {
    const loiteringMinDurValue =
      parseInt(loiteringMinDur) <= 1 ? 60 : parseInt(loiteringMinDur) * 60;
    const property = {
      'virtual-area-intrusion': intrusion?.toString(),
      'virtual-area-intrusion-min-dur': intrusionMinDur?.toString(),
      'virtual-area-loitering': loitering?.toString(),
      'virtual-area-loitering-min-dur': loiteringMinDurValue?.toString(),
      'virtual-area-entering': entering?.toString(),
      'virtual-area-exiting': exiting?.toString(),
      'virtual-area-appear-disappear': appearDisappear?.toString(),
      'virtual-area-appear-disappear-min-dur':
        appearDisappearMinDur?.toString(),
    };
    callBackMethod(property);
  }, [
    intrusion,
    intrusionMinDur,
    entering,
    exiting,
    loitering,
    loiteringMinDur,
    appearDisappear,
    appearDisappearMinDur,
  ]);

  return (
    <div className="component-container">
      <div className="object-type-title category">{title}</div>
      <div className="object-type-sub-title">{subtitle}</div>
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-area-intrusion',
        capVirtualAreaIVA,
      ) && (
        <Row className="wdr-view mt-3">
          <Col>
            <ToggleInput
              label={'Intrusion'}
              classes={'toggle-field-wrap-iva'}
              name="intrusion"
              value={intrusion}
              changeHandler={() => {
                setIntrusion((prevState) => !prevState);
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings || disableStatus}
            />
            <div className="min-duration-iva">
              {`Minimum Duration (up to ${modelData?.maxDuration} seconds)`}
            </div>
            {
              <InputSlider
                isErrorMessage={false}
                suffixString={'s'}
                classes={'mixed'}
                label={''}
                thumbLabelHide={true}
                fieldName="intrusionMinDur"
                minValue={modelData?.minDuration}
                maxValue={modelData?.maxDuration}
                currentValue={parseInt(intrusionMinDur)}
                changeHandler={(value) => {
                  setIntrusionMinDur(value.toString());
                }}
                disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                disabled={!canChangeSettings || !intrusion || disableStatus}
                isDetection={true}
              />
            }
          </Col>
        </Row>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-area-entering',
        capVirtualAreaIVA,
      ) && (
        <Row className=" mt-2">
          <Col>
            <ToggleInput
              label={'Enter'}
              classes={intrusion ? 'mixed-lower' : ''}
              name="entering"
              value={entering}
              changeHandler={() => {
                setEntering((prevState) => !prevState);
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings || disableStatus}
            />
          </Col>
        </Row>
      )}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-area-exiting',
        capVirtualAreaIVA,
      ) && (
        <Row className="mt-2">
          <Col>
            <ToggleInput
              label={'Exit'}
              classes={intrusion ? 'mixed-lower' : ''}
              name="exiting"
              value={exiting}
              changeHandler={() => {
                setExiting((prevState) => !prevState);
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings || disableStatus}
            />
          </Col>
        </Row>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-area-appear-disappear',
        capVirtualAreaIVA,
      ) && (
        <Row className="mt-2">
          <Col>
            <ToggleInput
              label={'Appear, Disappear'}
              classes={'toggle-field-wrap-iva'}
              name="appearDisappear"
              value={appearDisappear}
              changeHandler={() => {
                setAppearDisappear((prevState) => !prevState);
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings || disableStatus}
            />

            <div className="min-duration-iva">
              {`Minimum Duration (up to ${modelDataAppear?.maxDuration} seconds)`}
            </div>

            {
              <InputSlider
                isErrorMessage={false}
                suffixString={'s'}
                classes={'mixed'}
                label={''}
                thumbLabelHide={true}
                fieldName="appearDisappearMinDur"
                minValue={modelDataAppear?.minDuration}
                maxValue={modelDataAppear?.maxDuration}
                currentValue={parseInt(appearDisappearMinDur)}
                changeHandler={(value) => {
                  setAppearDisappearMinDur(value.toString());
                }}
                disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                disabled={
                  !canChangeSettings || !appearDisappear || disableStatus
                }
                isDetection={true}
              />
            }
          </Col>
        </Row>
      )}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-area-loitering',
        capVirtualAreaIVA,
      ) && (
        <Row className=" mt-2">
          <Col>
            <ToggleInput
              label={'Loitering'}
              classes={'toggle-field-wrap-iva'}
              name="loitering"
              value={loitering}
              changeHandler={() => {
                setLoitering((prevState) => !prevState);
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings || disableStatus}
            />

            <div className="min-duration-iva">
              {constants.MINIMUN_DURATION_LABEL_MIN.replace(
                '${range}',
                `${parseInt(modelDataloitering?.maxDuration) / 60}`,
              )}
            </div>
            {
              <InputSlider
                isErrorMessage={false}
                suffixString={'m'}
                classes={'mixed'}
                label={''}
                thumbLabelHide={true}
                fieldName="loiteringMinDur"
                minValue={modelDataloitering?.minDuration}
                maxValue={parseInt(modelDataloitering?.maxDuration) / 60}
                currentValue={parseInt(loiteringMinDur)}
                changeHandler={(value) => {
                  setLoiteringMinDur(value.toString());
                }}
                disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                disabled={!canChangeSettings || !loitering || disableStatus}
                isDetection={true}
              />
            }
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CategoryTypeIVA;
