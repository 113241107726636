const IncidentLinkExComplete = ({ styleStroke }) => {
  return (
    <div className="incident-img-1">
      <svg
        width="35"
        height="35"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ '--strokeColor': styleStroke }}
      >
        <path
          d="M14.8333 8.39518H3.16667M14.8333 8.39518C15.7538 8.39518 16.5 9.14137 16.5 10.0618V15.0618C16.5 15.9823 15.7538 16.7285 14.8333 16.7285H3.16667C2.24619 16.7285 1.5 15.9823 1.5 15.0618V10.0618C1.5 9.14137 2.24619 8.39518 3.16667 8.39518M14.8333 8.39518V6.72852C14.8333 5.80804 14.0871 5.06185 13.1667 5.06185M3.16667 8.39518V6.72852C3.16667 5.80804 3.91286 5.06185 4.83333 5.06185M4.83333 5.06185V3.39518C4.83333 2.47471 5.57953 1.72852 6.5 1.72852H11.5C12.4205 1.72852 13.1667 2.47471 13.1667 3.39518V5.06185M4.83333 5.06185H13.1667"
          stroke={styleStroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default IncidentLinkExComplete;
