import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
// import { useOrganizations } from '../../../store/OrganizationsStore';
import { MuuriComponent } from 'muuri-react';
import { useSelector, useDispatch } from 'react-redux';
import './LiveGridStructure.scss';
import LiveGridItem from './LiveGridItem';
import EditCamera from './EditCamera';
// import { getAllDevicesData } from '../../../store/reducers/AccountReducer';
import {
  getIsLeftMenuOpen,
  getSelectedView,
  getViewList,
  setSelectedView,
  setViewList,
} from '../../../store/reducers/ViewsReducer';
import { Utils, constants } from '../../../helpers';
import { SiteSpinner } from '../../../components/common';
import { resetRecievedOffers } from '../../../store/reducers/StreamingReducer';

const LiveGridStructure = (props) => {
  const {
    orgDetails,
    devicesData,
    currentViewDeviceList,
    setCurrentViewDeviceList,
    entitleData,
    metadataByDeviceId,
    fullscreen,
  } = props;
  const gridRef = useRef();
  const dispatch = useDispatch();
  const viewList = useSelector(getViewList);
  const isLeftMenuOpen = useSelector(getIsLeftMenuOpen);
  const selectedView = useSelector(getSelectedView);
  const itemCount = currentViewDeviceList?.length || 0;
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [isChangedSize, setIsChangedSize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [liveMetaData, setLiveMetaData] = useState([]);

  useEffect(() => {
    setLoading(!currentViewDeviceList?.length);
  }, [currentViewDeviceList?.length]);

  useEffect(() => {
    if (metadataByDeviceId) {
      setLiveMetaData(metadataByDeviceId);
    }
  }, [metadataByDeviceId]);

  useEffect(() => {
    const containerElem = document.getElementsByClassName(
      'camera-tile-container',
    )?.[0];
    if (containerElem) {
      containerElem.style.width = '100px';
      containerElem.style.height = '100px';
      const rowCol = Utils.getGridItemRowColumn(itemCount);
      const styles = calculateDimensions(...rowCol);
      containerElem.style.width = styles.rowWidth + 'px';
      containerElem.style.height = styles.columnHeight + 'px';
    }
    const timelineElem =
      document.getElementsByClassName('multiview-timeline')?.[0];
    const mainHeaderElem = document.getElementsByClassName(
      'device-wall-main-container-header',
    )?.[0];
    if (timelineElem && mainHeaderElem) {
      timelineElem.style.width = mainHeaderElem.offsetWidth + 'px';
    }
    window.dispatchEvent(new Event('resize'));
    setIsChangedSize(!isChangedSize);
  }, [
    currentViewDeviceList,
    isLeftMenuOpen,
    itemCount,
    fullscreen,
    document.getElementsByClassName('main-content-container')?.[0]
      ?.clientHeight,
    document.getElementsByClassName('main-content-container')?.[0]?.clientWidth,
  ]);

  useEffect(() => {
    dispatch(resetRecievedOffers());
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setIsChangedSize(!isChangedSize);
    }, 100);

    return () => {
      dispatch(resetRecievedOffers());
    };
  }, []);

  useEffect(() => {
    const mainContainerElem = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    const containerElem = document.getElementsByClassName(
      'camera-tile-container',
    )?.[0];
    if (mainContainerElem && containerElem) {
      const marginTop =
        mainContainerElem.clientHeight - containerElem.clientHeight - 20;
      containerElem.style.marginTop = marginTop / 2 + 'px';
    }
  }, [isChangedSize, itemCount]);

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length > 0) {
      const deviceData = devicesData.find(
        (device) => device.deviceId === deviceId,
      );
      return deviceData;
    }
    return null;
  };

  const getHubInfo = (deviceId) => {
    if (devicesData?.length > 0) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId,
      );
      return `${deviceData[0]?.gatewayId}`;
    }
  };

  const handleDragStart = (item) => {
    setDraggingItemId(item.getKey());
  };

  const handleDragEnd = async () => {
    setDraggingItemId(null);
    if (gridRef.current) {
      const reorderedDeviceIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      if (
        JSON.stringify(currentViewDeviceList) ===
        JSON.stringify(reorderedDeviceIds)
      ) {
        return;
      }
      setCurrentViewDeviceList(reorderedDeviceIds);
      const updatedView = Utils.getUpdatedViewPayload(
        selectedView,
        reorderedDeviceIds.filter((a) => !a.includes('edit')),
      );
      const response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        updatedView,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        },
      );
      const resData = response?.data;

      if (resData?.meta.code === 200) {
        dispatch(setSelectedView(updatedView));
        const updatedViewList = viewList.map((view) => {
          if (view.viewId === selectedView.viewId) {
            return updatedView;
          } else {
            return view;
          }
        });
        dispatch(setViewList(updatedViewList));
      }
    }
  };

  const calculateDimensions = (rows, columns) => {
    const container = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    if (!container) return; // Ensure container exists before proceeding.

    const totalContainerWidth = container.clientWidth || 0;
    const containerHeight = container.clientHeight || 0;

    if (!totalContainerWidth || !containerHeight) return; // If no dimensions are found, return early.

    // Adjust width based on left menu visibility
    const availableWidth = isLeftMenuOpen
      ? totalContainerWidth - 100
      : totalContainerWidth; // Assuming the left menu is 300px wide
    const aspectRatio = 16 / 9; // Default aspect ratio
    const rowWidth = availableWidth / columns; // Width of each column
    const columnHeight = containerHeight / rows; // Height of each row

    let tileWidth = rowWidth;
    let tileHeight = tileWidth / aspectRatio;

    // Adjust width and height to maintain the aspect ratio within the container
    if (tileHeight > columnHeight) {
      tileHeight = columnHeight;
      tileWidth = tileHeight * aspectRatio;
    }

    return {
      rowWidth: tileWidth * columns,
      columnHeight: tileHeight * rows,
    };
  };

  return (
    <div className={`camera-tile-container`}>
      {loading ? (
        <div className="loading-screen" style={{ position: 'absolute' }}>
          <SiteSpinner />
          <div className="simple-label">
            {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
          </div>
        </div>
      ) : (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={currentViewDeviceList.length > 1}
          instantLayout
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {currentViewDeviceList.map((deviceId) => {
            const deviceInfo = getDeviceInfo(deviceId);
            const hubInfo = getHubInfo(deviceId);

            return (
              <div
                key={deviceId}
                onMouseEnter={() => setHoveredDeviceId(deviceId)}
                onMouseLeave={() => setHoveredDeviceId(null)}
                className={`item-grid ${
                  draggingItemId !== deviceId ? '' : 'dragged-item'
                }
              ${hoveredDeviceId === deviceId ? 'add-hover' : ''}
            `}
                style={Utils.getResponsiveStyleProps(itemCount)}
              >
                {deviceInfo ? (
                  <LiveGridItem
                    key={deviceId}
                    layout={deviceId}
                    device={deviceInfo}
                    deviceId={deviceId}
                    hubId={hubInfo}
                    activeTime={props.activeTime}
                    timeZone={props.timeZone}
                    uniqueId={deviceId + selectedView?.viewId}
                    entitleData={entitleData}
                    metadataByDeviceId={liveMetaData}
                  />
                ) : (
                  <EditCamera />
                )}
              </div>
            );
          })}
        </MuuriComponent>
      )}
    </div>
  );
};

export default LiveGridStructure;
